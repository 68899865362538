

import Home from "./components/Home";
import {Routes, Route } from "react-router-dom";
import Contact from "./components/Contact";
import About from "./components/About";
import Products from "./components/Products";
import ProductDetails from "./components/ProductDetails";
import Header from "./Header";
import Footer from "./Footer";
import ProductsResult from "./components/ProductsResult";
import ProductsAdmin from "./components/ProductsAdmin";
import ProductDetailsAdmin from "./components/ProductDetailsAdmin";
import Login from "./components/Login";
import AddProduct from "./components/AddProduct";
import CheckoutCart from "./components/CheckoutCart";
import CheckoutInfo from "./components/CheckoutInfo";
import CheckoutComplete from "./components/CheckoutComplete";
import ScrollToTop from './components/ScrollToTop';
import Register from './components/Register';
import Categories from "./components/Categories";
import Devis from "./components/Devis";
import DevisDetails from "./components/DevisDetails";
import Projects from "./components/Projects";
import LellaKmar from "./components/LellaKmar";
import Manhalet from "./components/Manhalet";
import Healthy from "./components/Healthy";
import Bargou from "./components/Bargou";
import Isbst from "./components/Isbst";
import Oryx from "./components/Oryx";
import FavProducts from "./components/FavProducts";
import { useState , useEffect } from 'react';



export default function App() {
  const [showVideo, setShowVideo] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const hasShownVideo = sessionStorage.getItem('hasShownVideo');
    
    if (!hasShownVideo) {
      setShowVideo(true);
      sessionStorage.setItem('hasShownVideo', 'true');

      const timer = setTimeout(() => {
        setFadeOut(true); 
        setTimeout(() => {
          setShowVideo(false); 
        }, 1000); 
      }, 3000); 

      return () => clearTimeout(timer); 
    }
  }, []);

  return (
    <div className="App">
    {showVideo ? (
      <div className={`video-intro ${fadeOut ? 'fade-out' : ''}`}>
        <video
          autoPlay
          muted
          playsInline
          className="intro-video"
          onEnded={() => setShowVideo(false)} 
        >
          <source src="assets/media/video/intro.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    ) : (
    <>
      <div>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/Manhalet" element={<Manhalet />} />
          <Route path="/Healthy" element={<Healthy />} />
          <Route path="/Bargou" element={<Bargou />} />
          <Route path="/Isbst" element={<Isbst />} />
          <Route path="/Oryx" element={<Oryx />} />
          <Route path="/LellaKmar" element={<LellaKmar />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/FavProducts" element={<FavProducts />} />
          <Route path="/Devis" element={<Devis />} />
          <Route path="/DevisDetails" element={<DevisDetails />} />
          <Route path="/Categories" element={<Categories />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/AddProduct" element={<AddProduct />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/CheckoutCart" element={<CheckoutCart />} />
          <Route path="/CheckoutInfo" element={<CheckoutInfo />} />
          <Route path="/CheckoutComplete" element={<CheckoutComplete />} />
          <Route path="/About" element={<About />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Products" element={<Products />} />
          <Route path="/ProductDetails" element={<ProductDetails />} />
          <Route path="/ProductDetailsAdmin" element={<ProductDetailsAdmin />} />
          <Route path="/ProductsResult" element={<ProductsResult />} />
          <Route path="/ProductsAdmin" element={<ProductsAdmin />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </div>
    </>
     )}
    </div>
  );
}