import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import Loading from './Loading';
import { Helmet } from 'react-helmet';


function ProductsAdmin() {
  const [loading, setLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [priceFrom, setPriceFrom] = useState("");
  const [priceTo, setPriceTo] = useState("");
  const [sortCriteria, setSortCriteria] = useState('latest');
  const [sortOrder, setSortOrder] = useState('desc'); 
  const token = Cookies.get('token'); 
  const navigate = useNavigate();
  const location = useLocation();
  const categoryId = location.state?.categoryId;

  document.title = "Vos produits";

  useEffect(() => {
      if (!token) {
        navigate('/login'); 
        return;
      }
    const fetchProducts = async () => {
      try {
        const categoryIds = Array.isArray(categoryId) ? categoryId : [categoryId];
        

        if(categoryIds){
          const response = await axios.post(`${baseUrl}/get_products`, {
            categoryId: categoryIds,  
        });
        setProducts(response.data);
        setFilteredProducts(response.data);
      }
      else{
        console.log("fail")
      }
    }
    catch (error) {
        console.error('Error retrieving products:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
    document.title = "Nos produits";
  }, [token,categoryId]);

  useEffect(() => {
    filterProducts();
  }, [products, keyword, priceFrom, priceTo]);

  const filterProducts = () => {
    let filtered = products;

    if (keyword) {
      filtered = filtered.filter(product =>
        product.name.toLowerCase().includes(keyword.toLowerCase())
      );
    }

    if (priceFrom !== "" && priceTo !== "") {
      filtered = filtered.filter(product =>
        product.price >= priceFrom && product.price <= priceTo
      );
    }

    setFilteredProducts(filtered);
  };

  const handleKeywordFilterSubmit = (e) => {
    e.preventDefault();
    filterProducts();
  };

  const handlePriceFilterSubmit = (e) => {
    e.preventDefault();
    filterProducts();
  };

  const sortedProducts = [...filteredProducts];

  if (sortCriteria === 'latest') {
    sortedProducts.sort((a, b) => {
      return sortOrder === 'asc'
        ? new Date(a.created_at) - new Date(b.created_at)
        : new Date(b.created_at) - new Date(a.created_at);
    });
  } else if (sortCriteria === 'price') {
    sortedProducts.sort((a, b) => {
      return sortOrder === 'asc' ? a.price - b.price : b.price - a.price;
    });
  }

  const itemTitleStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%', 
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const productsPerPage = 12;

  const renderPagination = () => {
    const productsToDisplay = filteredProducts.length > 0 ? filteredProducts : products;
    const totalPages = Math.ceil(productsToDisplay.length / productsPerPage);
    const pages = [];
  
    for (let i = 1; i <= totalPages; i++) {
      const isActive = i === currentPage;
  
      pages.push(
        <li key={i} className={isActive ? 'active' : ''}>
          <a onClick={() => handlePageChange(i)}>
            {i}
          </a>
        </li>
      );
    }
  
    return (
      <div className="text-center">
        <ul className="pagination m-t-0">
          <li className={currentPage === 1 ? 'disabled' : ''}>
            <a onClick={() => handlePageChange(currentPage - 1)}>
              Précédent
            </a>
          </li>
          {pages}
          <li className={currentPage === totalPages ? 'disabled' : ''}>
            <a onClick={() => handlePageChange(currentPage + 1)}>
              Suivant
            </a>
          </li>
        </ul>
      </div>
    );
  }

  const handleSortChange = (criteria, event) => {
    event.preventDefault();
    if (criteria === sortCriteria) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortCriteria(criteria);
      setSortOrder('asc');
    }
  };
  
  const renderProducts = () => {
    const startIndex = (currentPage - 1) * productsPerPage;
    const endIndex = startIndex + productsPerPage;
  
    const productsToDisplay = sortedProducts.slice(startIndex, endIndex);
    if (productsToDisplay.length === 0) {
      return <p>Pas de résulats</p>;
    }
    return productsToDisplay.map((product) => (
      <div className="item item-thumbnail" key={product.id}>
        <div className="item-actions">
          <button  onClick={() => handleEditProduct(product.id)}>
            <i className="fa fa-pencil-square-o"></i>
          </button>
          <button  onClick={() => handleDeleteProduct(product.id)}>
            <i className="fa fa-trash-o"></i>
          </button>
        </div>
        <a 
          onClick={() =>
            navigate('/ProductDetailsAdmin', { state: { productId: product.id } })
          }
          className="item-image"
        >
          <div className={`image-wrapper ${product.photo_data_list[1] ? 'hover-effect' : ''}`}>
            <img
              src={`data:image/png;base64,${product.photo_data_list[0].data}`}
              alt={product.name}
              className="first-image"
              width="200"
              height="200"
            />
            {product.photo_data_list[1] && (
              <img
                src={`data:image/png;base64,${product.photo_data_list[1].data}`}
                alt={product.name}
                className="second-image"
                width="200"
                height="200"
              />
            )}
          </div>
        </a>
        <div className="item-info">
        <p className="item-desc">
            {product.brand}
          </p>
          <h4 className="item-title" style={itemTitleStyle}>
            <a
 onClick={() =>
  navigate('/ProductDetailsAdmin', { state: { productId: product.id } })
}              style={{ cursor: 'pointer' }}
            >
              {product.name}
            </a>
          </h4>
          <div className="item-price">{product.price !== null ? (
    <div className="price">{product.price.toFixed(3)} TND</div>
  ) : (
    <div>prix sur devis</div>
  )}</div>
        </div>
      </div>
    ));
  };
  
  
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    scrollToTop();
  };


  const handleDeleteProduct = async (productId) => {
    try {
      const response = await axios.delete(`${baseUrl}/delete_product/${productId}`);
      if (response.status === 200) {
        const updatedProducts = products.filter(product => product.id !== productId);
        setProducts(updatedProducts);
      }
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

    const handleEditProduct = (productId) => {
    navigate('/ProductDetailsAdmin', { state: { productId } });
  };
    return(
      <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
      <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
                 <style>
            {`
            .image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;            }

            .first-image {
              position: relative;
              top: 0;
              left: 0;
              transition: opacity 0.3s ease;
               width: auto;
  height: auto; 
            }

            .second-image {
              position: absolute;
              top: 0;
              left: 0;
              transition: opacity 0.3s ease;
              opacity: 0;
 width: auto;
  height: auto; 
  padding-left:70px;
            }

            .hover-effect:hover .first-image {
              opacity: 0;
            }

            .hover-effect:hover .second-image {
              opacity: 1;
            }
            `}
          </style>
            {products?(
<div>
        <div className="mt-bnr-inr overlay-wraper bg-parallax bg-top-center" data-stellar-background-ratio="0.5" style={{backgroundImage: "url(assets/images/banner/38.jpg)",backgroundPosition:"center"}}>
            	<div className="overlay-main bg-black opacity-07"></div>
                <div className="container">
                    <div className="mt-bnr-inr-entry">
                    	<div className="banner-title-outer">
                        	<div className="banner-title-name">
                        		<h1 className="m-b0">Nos Produits</h1>
                            </div>
                        </div>
                        
                            <div>
                                <ul className="mt-breadcrumb breadcrumb-style-2">
                                    <li><a href="/">Acceuil</a></li>
                                    <li>Produits</li>
                                </ul>
                            </div>
                        
                    </div>
                </div>
            </div>


        
    
        <div id="search-results" className="section-container bg-silver">
            <div className="container">
                <div className="search-container">
                  
                    <div className="search-content">
                       
                        <div className="search-toolbar">
                            
                            <div className="row">
                                <div className="col-md-6">
                                    <h4> {products.length} produits trouvés</h4>
             
                                </div>
                             
                                <div className="col-md-6 text-right">
    <ul className="sort-list">
        <li className="text"><i className="fa fa-filter"></i> Filtrer par:</li>
        <li>
    <a  onClick={(e) => handleSortChange('latest', e)}>Date d'ajout</a>
  </li>
  <li>
    <a  onClick={(e) => handleSortChange('price', e)}>Prix</a>
  </li>

    </ul>
</div>

                              
                            </div>
                        
                        </div>
                     
                        <div className="search-item-container">
                            
                            <div className="item-row">
                            <div className="product-list">
                            {renderProducts()}

      </div>

      
                            </div>
                           
                          
                            
                          
                        </div>
                       
                        {renderPagination()}

                    </div>
                    <div className="search-sidebar">
                  <h4 className="title">Filtrer par</h4>
                  <form onSubmit={handleKeywordFilterSubmit} name="keyword_filter_form">
                    <div className="form-group">
                      <label className="control-label">Mots clés</label>
                      <input
                        type="text"
                        className="form-control input-sm"
                        name="keyword"
                        value={keyword}
                        placeholder="Ecrire ici"
                        onChange={(e) => setKeyword(e.target.value)}
                      />
                    </div>
                  </form>

                  <form onSubmit={handlePriceFilterSubmit} name="price_filter_form">
                    <div className="form-group">
                      <label className="control-label">Prix</label>
                      <div className="row row-space-0">
                        <div className="col-md-5">
                          <input
                            type="number"
                            className="form-control input-sm"
                            name="price_from"
                            value={priceFrom}
                            placeholder="Prix Min"
                            onChange={(e) => setPriceFrom(e.target.value)}
                          />
                        </div>
                        <div className="col-md-2 text-center p-t-5 f-s-12 text-muted">à</div>
                        <div className="col-md-5">
                          <input
                            type="number"
                            className="form-control input-sm"
                            name="price_to"
                            value={priceTo}
                            placeholder="Prix Max"
                            onChange={(e) => setPriceTo(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </form>

                  <div>
                    {(filteredProducts.length === 0) && <p>Pas de résulats</p>}
                  </div>
                </div>
                
                </div>
            
            </div>
            
        </div>
        
    



                    <button className="scroltop"  onClick={scrollToTop}
          ><span className="fa fa-angle-up  relative" id="btn-vibrate"></span></button>
                  
             </div>  
         ):(           
          <div className="loading-area">
              <div className="loading-box"></div>
              <div className="loading-pic">
                  <div className="cssload-loader">Chargement</div>
              </div>
           
        
        
</div> 
 )}
        </div>
      )}
      </div>
      </>
    );
}
export default ProductsAdmin