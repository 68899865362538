import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import { Helmet } from 'react-helmet';

function AddProduct() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [productImgList, setProductImgList] = useState([]);
  const [description, setDescription] = useState('');
  const [priceOption, setPriceOption] = useState('');
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [brand, setBrand] = useState('');
  const [reference, setReference] = useState('');
  const [processingImages, setProcessingImages] = useState(false);
  const [categoryGroups, setCategoryGroups] = useState({});
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const token = Cookies.get('token');
  const [loading, setLoading] = useState(true);

  document.title = "Ajouter produit";

  useEffect(() => {
    if (!token) {
      navigate('/login'); 
      return;
    }

    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get_categories`); 
        const categories = response.data;
        const grouped = categories.reduce((acc, category) => {
          const [groupKey] = category.name.split('-');

          if (!acc[groupKey]) {
            acc[groupKey] = [];
          }

          acc[groupKey].push(category);
          return acc;
        }, {});

        setCategoryGroups(grouped);
      } catch (err) {
        setError('Failed to load categories');
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
    document.title = "Ajouter produit"; 
  }, [navigate, token, baseUrl]);

  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };  

  const handleChange = (e, field) => {
    const value = e.target.value;
    if (field === 'description') {
      setDescription(value);
    } else if (field === 'name') {
      setName(value);
    } else if (field === 'price') {
      setPrice(value);
    } else if (field === 'brand') {
      setBrand(value);
    } else if (field === 'reference') {
      setReference(value);
    }
  };

  const handleGroupChange = (e) => {
    const groupValue = e.target.value;
    setSelectedGroup(groupValue);
    setSelectedCategoryId(''); 
    if (groupValue === "autres") {
      setSelectedCategoryId(28);
    } else if (groupValue === "hygiéne et sécurité") {
      setSelectedCategoryId(27); 
    }
  };
  
  
  const handleCategoryChange = (e) => {
    setSelectedCategoryId(e.target.value);
  };
  const blobToBase64 = (blobUrl) => {
    return new Promise((resolve, reject) => {
      fetch(blobUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
        .catch(reject);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedCategoryId) {
      setError('Please select a category');
      return;
    }
    const imageDataUrls = await Promise.all(
      productImgList.map(async (img) => (img.processed ? await blobToBase64(img.src) : img.src))
    );
    const priceValue = price !== '' ? parseFloat(price) : null;
    const data = {
      category_id: selectedCategoryId,
      description: description,
      name: name,
      price: priceValue,
      brand: brand,
      reference: reference,
      image_data_list: imageDataUrls
    };

    try {
      await axios.post(`${baseUrl}/add_product`, data);
      setDescription('');
      setName('');
      setPrice('');
      setBrand('');
      setReference('');
      setSelectedCategoryId('');
      setProductImgList([]);
    } catch (error) {
      setError('Error adding product');
    }
  };

  function handleDragOver(event) {
    event.preventDefault();
  }

  const handleUploadClick = () => {
    document.getElementById("file-upload").click();
  };

  function handleDrop(event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleImageUpload(files);
  }


  
  const handleImageUpload = async (files) => {
    setProcessingImages(true);
    const imageDataUrls = [];
  
    for (const file of files) {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        imageDataUrls.push(event.target.result);
        if (imageDataUrls.length === files.length) {
          setProductImgList((prevList) => [...prevList, ...imageDataUrls.map(src => ({ src, processed: false }))]);
          setProcessingImages(false);
        }
      };
  
      reader.readAsDataURL(file);
    }
    if (files.length === 0) {
      setProcessingImages(false);
    }
  };
  
  const handleRemoveImage = (index) => {
    setProductImgList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const removeBackground = async (imgSrc) => {
    setProcessingImages(true);
  
    const base64Image = imgSrc.split(',')[1];
    if (!base64Image) {
      console.error('Base64 image data is undefined or null');
      setProcessingImages(false);
      return;
    }
  
    try {
      const response = await axios.post(
        'https://api.remove.bg/v1.0/removebg',
        {
          image_file_b64: base64Image
        },
        {
          headers: {
            'X-Api-Key': 'zdkUuSTnCvuscyaxHH2JHBvf',
            'Content-Type': 'application/json'
          },
          responseType: 'arraybuffer',
        }
      );
  
      if (response.data) {
        const processedBlob = new Blob([response.data], { type: 'image/png' });
        const processedUrl = URL.createObjectURL(processedBlob);
  
        setProductImgList((prevList) =>
          prevList.map((item) =>
            item.src === imgSrc ? { src: processedUrl, processed: true } : item
          )
        );
      } else {
        console.error('API response data is empty or invalid');
      }
    } catch (error) {
      console.error('Error processing image:', error);
    } finally {
      setProcessingImages(false);
    }
  };
  
  return (
    <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="section-full p-t80 p-b50 square_shape2 mobile-page-padding">
          <div className="section-full p-tb80 inner-page-padding">
            <div className="container">
              <div className="section-content">
                <div className="row">
                  <div className="col-md-8 col-sm-6">
                    <form onSubmit={handleSubmit} className="contact-form cons-contact-form">
                      <div className="contact-one m-b30">
                        <div className="section-head">
                          <div className="mt-separator-outer separator-left">
                            <div className="mt-separator">
                              <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Ajouter</span> produit</h2>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <input 
                            onChange={(e) => handleChange(e, 'name')}
                            type="text"
                            name="name"
                            id="name"
                            className="form-control form-control-lg"
                            placeholder='nom'
                            value={name} 
                            required
                          />
                        </div>
                        <div className="form-group">
                          <input 
                            onChange={(e) => handleChange(e, 'brand')}
                            type="text"
                            name="brand"
                            id="brand"
                            className="form-control form-control-lg"
                            placeholder='Marque'
                            value={brand} 
                            required
                          />
                        </div>
                        <div className="form-group">
                          <input 
                            onChange={(e) => handleChange(e, 'reference')}
                            type="text"
                            name="reference"
                            id="reference"
                            className="form-control form-control-lg"
                            placeholder='Réference'
                            value={reference} 
                            required
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            onChange={(e) => handleChange(e, 'description')}
                            name="description"
                            id="description"
                            className="form-control form-control-lg"
                            placeholder="Description"
                            value={description}
                            required
                          />
                        </div>

                        <div className="form-group">
                          <div className="radio-group">
                            <label className={`radio-label ${priceOption === "withPrice" ? "active" : ""}`}>
                              <input 
                                type="radio" 
                                value="withPrice" 
                                checked={priceOption === "withPrice"} 
                                onChange={() => setPriceOption("withPrice")}
                              />
                              Avec prix
                            </label>
                            <label className={`radio-label ${priceOption === "withoutPrice" ? "active" : ""}`}>
                              <input 
                                type="radio" 
                                value="withoutPrice" 
                                checked={priceOption === "withoutPrice"} 
                                onChange={() => setPriceOption("withoutPrice")}
                              />
                              Sans prix
                            </label>
                          </div>
                        </div>

                        {priceOption === "withPrice" && (
                          <div className="form-group">
                            <input 
                              onChange={(e) => handleChange(e, 'price')}
                              type="text"
                              name="price"
                              id="price"
                              className="form-control form-control-lg"
                              placeholder='Prix'
                              value={price}
                              required
                            />
                          </div>
                        )}

                        <div className="form-group">
                          <select 
                            onChange={handleGroupChange} 
                            className="form-control form-control-lg" 
                            value={selectedGroup}
                            required
                          >
                            <option value="">Choisir une catégorie</option>
{Object.keys(categoryGroups).map((groupKey) => (
  <option key={groupKey} value={groupKey}>
    {capitalizeWords(groupKey)}
  </option>
))}
                          </select>
                        </div>

                       {selectedGroup && selectedGroup !== "autres" && selectedGroup !== "hygiéne et sécurité" && (
  <div className="form-group">
    <select 
      onChange={handleCategoryChange} 
      className="form-control form-control-lg" 
      value={selectedCategoryId} 
      required
    >
      <option value="">Choisir une sous-catégorie</option>
      {categoryGroups[selectedGroup]?.map(category => {
        const parts = category.name.split('-');
        const nameAfterHyphen = parts[1] ? capitalizeWords(parts[1].trim()) : '';
        
        return (
          <option key={category.id} value={category.id}>
            {nameAfterHyphen}
          </option>
        );
      })}
    </select>
  </div>
)}



                        <div className="form-group">
                          <div className="input-container">
                            <label htmlFor="place-name-input">Photos du produit :</label>
                            <a onClick={handleUploadClick} style={{ textDecoration: 'none' }}>
                              <div
                                className="drop-area"
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                              >
                                <i className="fa fa-cloud-upload"></i>
                                <p>Glisser et déposer l'image ici</p>
                                <p>Ou</p>
                                <input
                                  id="file-upload"
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => handleImageUpload(e.target.files)}
                                  hidden
                                  style={{ display: "none" }} 
                                />
                                Appuyer pour télécharger
                              </div>
                            </a>
                            {productImgList.map((img, index) => (
                              <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                                <img src={img.src} alt={`Product ${index + 1}`} />
                                {!img.processed && (
                                  <div>
                                    <button
                                      style={{ marginRight: "10px" }}
                                      type="button"
                                      onClick={() => removeBackground(img.src)}
                                    >
                                      Supprimer arrière-plan
                                    </button>
                                    <button
                                      style={{ marginRight: "10px" }}
                                      type="button"
                                      onClick={() => handleRemoveImage(index)}
                                    >
                                      Supprimer
                                    </button>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                        
                        {error && 
                          <div className="alert alert-danger fade show">{error}</div>
                        }

                        <div className="text-right">
                          <button
                            type="submit"
                            className="site-button btn-effect"
                            style={{ marginTop: "20px" }}
                          >
                            Ajouter
                          </button> 
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {processingImages && (
            <div className="loading-overlay">
              <div className="loading-indicator">
                <ClipLoader color="#F5BF23" loading={processingImages} size={50} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
    </>
  );
}

export default AddProduct;
