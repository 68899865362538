import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Loading from './Loading';
import Cookies from "js-cookie";
import { useNavigate, useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ClipLoader from 'react-spinners/ClipLoader';
import { Helmet } from 'react-helmet';

function DevisDetails() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [devisDetails, setDevisDetails] = useState([]);
  const [error, setError] = useState(null);
  const [processingEmail, setProcessingEmail] = useState(false);
  const [editing, setEditing] = useState(null);
  const [signature, setSignature] = useState(null);
  const [tempValues, setTempValues] = useState({});
  const [progress, setProgress] = useState(0);  
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const location = useLocation();
  const capitalize = (str) => 
    str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    document.title = "Demande de devis";

  const { created_at, client_email,devisNumber } = location.state || {};
  const anyNaN = devisDetails.flatMap(devis => devis.products || [])
  .some(product => isNaN(parseFloat(tempValues[`${product.id}-price`] || product.price)) || isNaN(parseFloat(tempValues[`${product.id}-tva`] || product.tva)));

  const fetchDevisDetails = useCallback(async () => {
    try {
      const response = await axios.post(`${baseUrl}/get_devis_details`, {
        created_at_str: created_at,
        client_email: client_email
      });
      setDevisDetails(response.data);
    } catch (err) {
      setError('Failed to fetch devis details');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [created_at, client_email, baseUrl]);
  
  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    const userId = sessionStorage.getItem('id');
    const fetchSignature = async () => {
      const userId = sessionStorage.getItem('id'); 
      
      if (!userId) {
        console.error('User ID not found in sessionStorage.');
        return;
      }
      
      try {
        const response = await axios.get(`${baseUrl}/get_signature`, {
          params: { user_id: userId }, 
          headers: { 'Content-Type': 'application/json' }
        });
        setSignature(response.data.signature); 
      } catch (error) {
        setError('Error retrieving signature');
        console.error('Error retrieving signature:', error);
      }
    }
  
    if (!created_at || !client_email) {
      setError('No state information available');
      setLoading(false);
      return;
    }
  
    fetchDevisDetails();
    fetchSignature();
  }, [created_at, client_email, baseUrl, token, navigate, fetchDevisDetails]);
  
  
  const handleInputChange = (e, productIndex, field) => {
    setTempValues({
      ...tempValues,
      [`${productIndex}-${field}`]: e.target.value
    });
  };

  const handleSave = async (productIndex) => {
    const product = devisDetails.flatMap(devis => devis.products || [])[productIndex];
    const devisId = devisDetails.find(devis => devis.products.includes(product))?.id;

    try {
      await axios.post(`${baseUrl}/update_product`, {
        id: devisId,
        price: tempValues[`${productIndex}-price`] || product.price,
        tva: tempValues[`${productIndex}-tva`] || product.tva
      });

      const updatedDevisDetails = devisDetails.map(devis => {
        if (devis.id === devisId) {
          return {
            ...devis,
            products: devis.products.map((p, index) =>
              index === productIndex
                ? { ...p, price: tempValues[`${productIndex}-price`] || p.price, tva: tempValues[`${productIndex}-tva`] || p.tva }
                : p
            )
          };
        }
        return devis;
      });

      setDevisDetails(updatedDevisDetails);
      setEditing(null);
      window.location.reload(); 
    } catch (err) {
      console.error('Failed to update product', err);
    }
  };

  const formatPrice = (price) => parseFloat(price).toFixed(3);

  const calculateTotalHT = () => {
    return devisDetails.flatMap(devis => devis.products || [])
      .reduce((total, product) => {
        const price = parseFloat(tempValues[`${product.id}-price`] || product.price) || 0;
        const quantity = parseFloat(product.quantity) || 0;
        return total + (price * quantity);
      }, 0).toFixed(3);
  };

  const calculateTotalTTC = () => {
    return devisDetails.flatMap(devis => devis.products || [])
      .reduce((total, product) => {
        const price = parseFloat(tempValues[`${product.id}-price`] || product.price) || 0;
        const tva = parseFloat(tempValues[`${product.id}-tva`] || product.tva) || 0;
        const quantity = parseFloat(product.quantity) || 0;
        return total + (price * quantity * (1 + tva / 100));
      }, 0).toFixed(3);
  };

  if (loading) return <Loading />;

  const clientInfo = devisDetails.length > 0 ? devisDetails[0] : null;

  const allProducts = devisDetails.flatMap(devis => devis.products || []);



  const downloadPDF = () => {
    const element = document.getElementById('pdf-area');
  
    element.style.border = 'none';

    const wrapper = document.createElement('div');
    wrapper.style.width = '210mm';
    wrapper.style.height = '297mm';
    wrapper.style.position = 'relative';
    wrapper.style.overflow = 'hidden';
    wrapper.style.backgroundImage = 'url("/assets/images/entete.png")'; 
    wrapper.style.backgroundSize = 'cover';
    wrapper.style.backgroundPosition = 'center'; 
    wrapper.style.paddingTop = '20mm'; 

    wrapper.appendChild(element.cloneNode(true));
    document.body.appendChild(wrapper);
  
    const invoiceBtns = wrapper.querySelectorAll('.invoice-btns');
    const actionsColumns = wrapper.querySelectorAll('.actions-column');
  
    invoiceBtns.forEach(btn => btn.style.display = 'none');
    actionsColumns.forEach(col => col.style.display = 'none');
  
    const invoiceElement = wrapper.querySelector('.invoice');
    invoiceElement.classList.add('transparent-bg');
  
    html2canvas(wrapper, { scale: 2 }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('portrait', 'mm', 'a4');
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('devis.pdf');
      
        invoiceBtns.forEach(btn => btn.style.display = '');
        actionsColumns.forEach(col => col.style.display = '');
      
        invoiceElement.classList.remove('transparent-bg');
        document.body.removeChild(wrapper);
    });
};


const sendEmailWithPDF = async () => {
  if (anyNaN) {
    alert("Certains valeurs sont invalides (NaN). Veuillez vérifier les détails du devis.");
    return;
  }

  if (processingEmail) {
    return; 
  }

  setProcessingEmail(true);
  setProgress(0);

  let clonedElement, pdfBlob;

  try {
    const element = document.getElementById('pdf-area');
    if (!element) {
      alert('Erreur: zone PDF introuvable.');
      return;
    }

    element.style.border = 'none';

    const wrapper = document.createElement('div');
    wrapper.style.width = '210mm';
    wrapper.style.height = '297mm';
    wrapper.style.position = 'relative';
    wrapper.style.overflow = 'hidden';
    wrapper.style.backgroundImage = 'url("/assets/images/entete.png")'; 
    wrapper.style.backgroundSize = 'cover';
    wrapper.style.backgroundPosition = 'center'; 
    wrapper.style.paddingTop = '20mm'; 

    wrapper.appendChild(element.cloneNode(true));
    document.body.appendChild(wrapper);
  
    const invoiceBtns = wrapper.querySelectorAll('.invoice-btns');
    const actionsColumns = wrapper.querySelectorAll('.actions-column');
  
    invoiceBtns.forEach(btn => btn.style.display = 'none');
    actionsColumns.forEach(col => col.style.display = 'none');
  
    const invoiceElement = wrapper.querySelector('.invoice');
    invoiceElement.classList.add('transparent-bg');
  
    // Ensure html2canvas resolves before proceeding with email sending
    await html2canvas(wrapper, { scale: 2 }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('portrait', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdfBlob = pdf.output('blob');
    });

    if (!pdfBlob) {
      throw new Error('Le fichier PDF n\'a pas pu être généré.');
    }
    document.body.removeChild(wrapper);
    setProgress(50);

    // Send the email with the PDF blob
    try {
      const formData = new FormData();
      formData.append('pdf', pdfBlob, 'devis.pdf');
      formData.append('clientEmail', clientInfo.client_email);
      formData.append('clientName', capitalize(clientInfo.client_name));
    
      const response = await axios.post(`${baseUrl}/send-invoice`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    
      setProgress(70); 
    
      if (response.data.message === 'Email sent successfully') {
        setProgress(100);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error("❌ Erreur lors de l'envoi de l'email:", error);
      alert('❌ Une erreur est survenue lors de l\'envoi de l\'email. Veuillez réessayer.');
      setProgress(0); 
    }

  } catch (error) {
    console.error('❌ Erreur:', error);
    alert('❌ Une erreur est survenue. Veuillez réessayer.');
    setProgress(0);
  } finally {
    setProcessingEmail(false); 
  }
};





  const handleDelete = async (devisId) => {
    const userConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ce produit ?");
    
    if (userConfirmed) {
      try {
        await axios.post(`${baseUrl}/delete_devis`, { id: devisId });
        alert('Produit supprimé avec succès');
        
        const isLastDevis = devisDetails.length === 1;
  
        if (isLastDevis) {
          navigate('/devis');
        } else {
          setDevisDetails(devisDetails.filter(devis => devis.id !== devisId));
        }
        
      } catch (err) {
        console.error('Échec de la suppression du produit', err);
        alert('Échec de la suppression du produit');
      }
    }
  };
  



  return (
    <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
    <div>
      {error && <p>{error}</p>}
      {allProducts.length > 0 ? (
        <div className="invoice-wrapper" >
          <div className="invoice"  id="pdf-area">
            <div className="invoice-container" >
              <div className="invoice-head">
              <div style={{height:"50px"}}></div>
                <div className="hr"></div>
                <div className="invoice-head-middle">
                  <div className="invoice-head-middle-left text-start">
                    <p><span className="text-bold">Date</span>: {new Date(clientInfo.created_at).toLocaleDateString()}</p>
                  </div>
                  <div className="invoice-head-middle-right text-end">
                    <p><span className="text-bold">Devis N°:</span> {devisNumber}</p>
                  </div>
                </div>
                <div className="hr"></div>
                <div className="invoice-head-bottom">
                  <div className="invoice-head-bottom-left">
                    <ul>
                      <li className='text-bold'>Client:</li>
                      <li>Nom : {capitalize(clientInfo.client_name)}</li>
                      <li>Email : {clientInfo.client_email}</li>
                      <li>Numéro : (216) {clientInfo.client_phone}</li>
                    </ul>
                  </div>
                  <div className="invoice-head-bottom-right">
                    <ul className="text-end">
                      <li className='text-bold'>Description:</li>
                      <li>{clientInfo.description}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="invoice-body">
                <table>
                  <thead>
                    <tr>
                      <td className="text-bold">Produit</td>
                      <td className="text-bold">Reference</td>
                      <td className="text-bold">Quantité</td>
                      <td className="text-bold">Prix HT</td>
                      <td className="text-bold">TVA</td>
                      <td className="text-bold actions-column">Actions</td>
                    </tr>
                  </thead>
                  <tbody>
  {allProducts.map((product, index) => {
    const devis = devisDetails.find(devis => devis.products.includes(product));
    return (
      <tr key={index}>
                        <td style={{ wordBreak: 'break-word' }}>{product.name}</td>
                        <td style={{ textAlign: 'center' }}>{product.reference}</td>
                        <td style={{ textAlign: 'center' }}>{product.quantity}</td>
                        <td>{editing === index ? (
                          <input
                            type="text"
                            value={tempValues[`${index}-price`] || formatPrice(product.price) || ''}
                            onChange={(e) => handleInputChange(e, index, 'price')}
                          />
                        ) : (
                          formatPrice(product.price) || 'N/A'
                        )}</td>
                        <td>{editing === index ? (
                          <input
                            type="text"
                            value={tempValues[`${index}-tva`] || formatPrice(product.tva) || ''}
                            onChange={(e) => handleInputChange(e, index, 'tva')}
                          />
                        ) : (
                          product.tva+ '%' || 'N/A'
                        )}</td>
                          <td className="actions-column">
                          {editing === index ? (
                            <div className= "invoice-btns">
                            <button type="button" className="invoice-btn"  onClick={() => handleSave(index)}>
                      <span>
                        <i className="fa-solid fa-save"></i>
                      </span>
                      <span> Enregistrer</span>
                    </button>              </div>
                          ) : (
                            <div className= "invoice-btns">
                            <button type="button" className="invoice-btn"   onClick={() => setEditing(index)}>
                      <span>
                        <i className="fa-solid fa-edit" style={{marginRight:"10px"}}></i>
                      </span>
                      <span> Modifier</span>
                    </button>              </div>
                          )}
                          <div className= "invoice-btns">
                            <button type="button" className="invoice-btn"   onClick={() => handleDelete(devis.id)}>
                      <span>
                        <i className="fa-solid fa-trash" style={{marginRight:"5px"}}></i>
                      </span>
                      <span>Supprimer</span>
                    </button>              </div>
                        </td>
                        </tr>
    );
  })}
</tbody>
                </table>
                <div className="invoice-body-bottom">
                  <div className="invoice-body-info-item border-bottom">
                    <div className="info-item-td text-end text-bold">Total HT:</div>
                    <div className="info-item-td text-end">{calculateTotalHT()} DT</div>
                  </div>
                  <div className="invoice-body-info-item border-bottom">
                    <div className="info-item-td text-end text-bold">TVA:</div>
                    <div className="info-item-td text-end">{(calculateTotalTTC() - calculateTotalHT()).toFixed(3)} DT</div>
                  </div>
                  <div className="invoice-body-info-item">
                    <div className="info-item-td text-end text-bold">Total TTC:</div>
                    <div className="info-item-td text-end">{calculateTotalTTC()} DT</div>
                  </div>
                </div>
              </div>
              <div className="invoice-foot text-center">
  <p><span className="text-bold">NB:&nbsp;</span>Validité de l'offre 30 jours <br/>
  Conditions de livraison : Les frais de transport et d'installation sont à la charge du client</p>

  <div className="signature-container">
      <img src="assets/images/stamp.png" alt="Stamp" className="stamp" />
      {signature ? (
        <img src={signature} alt="User Signature" className="signature" />
      ) : (
        error ? <p>{error}</p> : <p>Loading signature...</p>
      )}
    </div>


  <div className="invoice-btns">
    <button type="button" className="invoice-btn" onClick={downloadPDF} style={{marginRight:"10px"}}>
      <span>
        <i className="fa-solid fa-file-pdf"></i>
      </span>
      <span> Télécharger PDF</span>
    </button>
    <button
  type="button"
  className="invoice-btn"
  onClick={sendEmailWithPDF}
  disabled={processingEmail}
  style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
>
  <span>
    <i className="fa-solid fa-envelope"></i>
  </span>
  <span>Envoyer Devis</span>
  {processingEmail ? (
    <ClipLoader color="#F5BF23" loading={processingEmail} size={20} />
  ) : (
    progress === 100 && (
      <span style={{ color: 'green', fontSize: '18px' }}>
        <i className="fa-solid fa-check-circle"></i>
      </span>
    )
  )}
</button>


  </div>
</div>


            </div>
          </div>
        </div>
      ) : (
        <p>Pas de produits pour ce Devis.</p>
      )}
    </div>
    </>
  );
}

export default DevisDetails;

