import { useState , useEffect } from 'react';
import React from "react"
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; 
import { TypeAnimation } from 'react-type-animation';
import { useNavigate } from 'react-router-dom';
import PartnersCarousel from './PartnersCarousel';
import PartnersCarousel2 from './PartnersCarousel2';
import Cookies from "js-cookie";
import Categories from './Categories';



function Home(){
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loadingProducts, setLoadingProducts] = useState(true);
    const token = Cookies.get("token");
    const detailsPage = token ? '/ProductDetailsAdmin' : '/ProductDetails';
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [selectedCategory, setSelectedCategory] = useState(0); 
    const [subcategoriesToFetch, setSubcategoriesToFetch] = useState([null]); 
    const [categoryProducts, setCategoryProducts] = useState([]);
    const navigate = useNavigate();
    document.title = "MAFI | Maison de Fournitures Industrielles";
    const isNewProduct = (createdDate) => {
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
      const productDate = new Date(createdDate);
    
      return productDate > oneMonthAgo;
    };
    const itemTitleStyle = {
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textAlign: 'center',
      cursor: 'pointer',
      textTransform: 'capitalize',
      height: '42px',
      lineHeight: '20px',
    };
    

    
    const carouselStyle = {
        position: 'relative',
        display: 'block',
        maxWidth: '100%',
        height: 'auto',
      };
    
      const handleSlideChange = (index) => {
        setCurrentSlide(index);
      };

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth', 
        });
      };

      const categoryMappings = {
        0: [null], 
        1: [1, 2, 3], 
        2: [4,5,6,7],
        3: [8,9,10,11], 
        4: [12,13,14,15,16,17],
        5: [18,19,20],
        6: [21,22,23,24,25,26],
        7: [27]
      };
      
   

     
useEffect(() => {
  const fetchCategoryProducts = async () => {
      try {
          const response = await axios.post(
            `${baseUrl}/get_first_products`,
            { categoryId: subcategoriesToFetch },  
            { headers: { 'Content-Type': 'application/json' } }
        );
        
        setCategoryProducts(response.data);
        setLoadingProducts(false); 
        
      } catch (error) {
          console.error('Error retrieving category products:', error);
      }
  };

  if (subcategoriesToFetch.length > 0) {
      setLoadingProducts(true); 
      fetchCategoryProducts();
  } else {
      setCategoryProducts([]);
      setLoadingProducts(false); 
  }
}, [subcategoriesToFetch]); 

const handleCategoryChange = (category, e) => {
  setSelectedCategory(category);

  if (categoryMappings[category]) {
      setSubcategoriesToFetch(categoryMappings[category]);
  } else {
      setSubcategoriesToFetch([]);
  }
};

    return (
      <div>
    
        <div>
       <div className="page-wraper"> 
        
        
        <div className="page-content">
  <Carousel showThumbs={false} style={carouselStyle} swipeable={false} autoPlay={true} interval={5000} showStatus={false}  infiniteLoop={true}   onChange={handleSlideChange} >
 
  <div style={{ position: 'relative' }}>
  <div className="mt-bnr-inr overlay-wraper bg-parallax bg-top-center"  data-stellar-background-ratio="0.5" style={{backgroundImage: "url(/assets/images/banner/40.jpg)",height:'600px',backgroundPosition:"center"}}>
    <div className="overlay-main bg-black opacity-05"></div>
    <div className="container">
      <div className="mt-bnr-inr-entry">
        <div className="banner-title-outer">
          <div className="banner-title-name">
            <h1 className="m-b0 montserrat-font" style={{color:"white"}}>
            {currentSlide === 0 && (
                      <TypeAnimation 
                        key={currentSlide}
                        sequence={[
                          1500,
                          'Fournisseur de solutions',
                        ]}
                      />
                    )}
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div style={{ position: 'relative' }}>
  <div className="mt-bnr-inr overlay-wraper bg-parallax bg-top-center"  data-stellar-background-ratio="0.5" style={{backgroundImage: "url(/assets/images/banner/36.jpg)",height:'600px'}}>
    <div className="overlay-main bg-black opacity-05"></div>
    <div className="container">
      <div className="mt-bnr-inr-entry">
        <div className="banner-title-outer">
          <div className="banner-title-name">
            <h1 className="m-b0 montserrat-font"style={{color:"white"}}>
            {currentSlide === 1 && (
                      <TypeAnimation 
                        key={currentSlide}
                        sequence={[
                          1500,
                          'Votre solution clés en mains!',
                        ]}
                      />
                    )}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div style={{ position: 'relative' }}>
  <div className="mt-bnr-inr overlay-wraper bg-parallax bg-center"  data-stellar-background-ratio="0.5" style={{backgroundImage: "url(/assets/images/banner/14.jpeg)",height:'600px'}}>
    <div className="overlay-main bg-black opacity-05"></div>
    <div className="container">
      <div className="mt-bnr-inr-entry">
        <div className="banner-title-outer">
          <div className="banner-title-name">
            <h1 className="m-b0 montserrat-font"style={{color:"white"}}>{currentSlide === 2 && (
                      <TypeAnimation 
                        key={currentSlide}
                        sequence={[
                          1500,
                          'À vous de rêver! À nous de réaliser',
                        ]}
                      />
                    )}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div style={{ position: 'relative' }}>
  <div className="mt-bnr-inr overlay-wraper bg-parallax bg-top"  data-stellar-background-ratio="0.5" style={{backgroundImage: "url(/assets/images/banner/1.jpg)",height:'600px'}}>
    <div className="overlay-main bg-black opacity-05"></div>
    <div className="container">
      <div className="mt-bnr-inr-entry">
        <div className="banner-title-outer">
          <div className="banner-title-name">
            <h1 className="m-b0 montserrat-font"style={{color:"white"}}>
            {currentSlide === 3 && (
                      <TypeAnimation 
                        key={currentSlide}
                        sequence={[
                          1500,
                          'L’ingénierie derrière chaque grain',
                        ]}
                      />
                    )}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div style={{ position: 'relative' }}>
  <div className="mt-bnr-inr overlay-wraper bg-parallax bg-top"  data-stellar-background-ratio="0.5" style={{backgroundImage: "url(/assets/images/banner/2.jpg)",height:'600px'}}>
    <div className="overlay-main bg-black opacity-05"></div>
    <div className="container">
      <div className="mt-bnr-inr-entry">
        <div className="banner-title-outer">
          <div className="banner-title-name">
            <h1 className="m-b0 montserrat-font"style={{color:"white"}}>
            {currentSlide === 4 && (
                      <TypeAnimation 
                        key={currentSlide}
                        sequence={[
                          1500,
                          'À l’origine des saveurs, une richesse cultivée',
                        ]}
                      />
                    )}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div style={{ position: 'relative' }}>
  <div className="mt-bnr-inr overlay-wraper bg-parallax bg-top"  data-stellar-background-ratio="0.5" style={{backgroundImage: "url(/assets/images/banner/3.jpg)",height:'600px'}}>
    <div className="overlay-main bg-black opacity-05"></div>
    <div className="container">
      <div className="mt-bnr-inr-entry">
        <div className="banner-title-outer">
          <div className="banner-title-name">
            <h1 className="m-b0 montserrat-font"style={{color:"white"}}>
            {currentSlide === 5 && (
                      <TypeAnimation 
                        key={currentSlide}
                        sequence={[
                          1500,
                          'Saveurs Authentiques, Savoir-Faire Unique',
                        ]}
                      />
                    )}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


  

 
</Carousel>

<Categories/>
            <div className="section-full p-t80 p-b50 bg-white mobile-page-padding">
                <div className="container">

						<div className="section-content">
                        	<div className="row">
                            
                                <div className="col-md-7 col-sm-6">
                                	<div className="video-section-full-v2">
                                    <div className="video-section-full bg-no-repeat bg-cover overlay-wraper m-b30" style={{ position: 'relative' }}>
    <div className="overlay-main "></div>
    <div className="video-section-inner" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex:'-1' }}>
        <div className="video-section-content" style={{ width: '100%', height: '100%' }}>
            <video className="mfp-video" autoPlay loop muted style={{ width: '100%', height: '100%',marginTop:'-30px' }} playsInline>
                <source src="assets/media/video/mafi.mov" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    </div>
</div>


                                    </div>                                      	                                	
                                </div>                            
                            	<div className="col-md-5 col-sm-6">
                                    <div className="video-part-2">
                                            <h3><span className="font-weight-100">L'excellence industrielle à portée de main, avec</span> MAFI</h3>
     										<p>Notre entreprise s'engage à fournir des solutions innovantes et fiables pour répondre à vos besoins les plus exigeants. Avec une équipe expérimentée et passionnée, nous mettons tout en œuvre pour concrétiser vos idées et transformer vos projets en réalité. Nous combinons notre expertise technique, notre créativité et notre souci du détail pour livrer des équipements de pointe, adaptés à vos spécifications.
                                             Votre réussite est notre plus grande satisfaction.</p> 
                                             {token? (
                                                      <ul className="list-angle-right anchor-line">
                                                      <li><a  onClick={() => navigate('/ProductsAdmin', { state: { categoryId: [1,2,3] } })}>Lait & Dérivés</a></li>
                                                      <li><a  onClick={() => navigate('/ProductsAdmin', { state: { categoryId: [4,5,6,7] } })}>Huiles & Huiles Essentielles</a></li>
                                                      <li><a  onClick={() => navigate('/ProductsAdmin', { state: { categoryId: [8,9,10,11] } })}>Valorisation Des Grains</a></li>
                                                      <li><a  onClick={() => navigate('/ProductsAdmin', { state: { categoryId: [12,13,14,15,16,17] } })}>Plantes Aromatiques & Médicinales</a></li>
                                                      <li><a  onClick={() => navigate('/ProductsAdmin', { state: { categoryId: [18,19,20] } })}>Cosmétiques Pharmaceutique & Parapharmaceutique</a></li>
                                                      <li><a  onClick={() => navigate('/ProductsAdmin', { state: { categoryId: [21,22,23,24,25,26] } })}>Confiseries & Cuisines</a></li>

                                                  </ul> 
                                        
                                             ):( 
                                              <ul className="list-angle-right anchor-line">
                                              <li><a  onClick={() => navigate('/Products', { state: { categoryId: [1,2,3]} })}>Lait & Dérivés</a></li>
                                              <li><a  onClick={() => navigate('/Products', { state: { categoryId: [4,5,6,7] } })}>Huiles & Huiles Essentielles</a></li>
                                              <li><a  onClick={() => navigate('/Products', { state: { categoryId: [8,9,10,11] } })}>Valorisation Des Grains</a></li>
                                              <li><a  onClick={() => navigate('/Products', { state: { categoryId: [12,13,14,15,16,17] } })}>Plantes Aromatiques & Médicinales</a></li>
                                              <li><a  onClick={() => navigate('/Products', { state: { categoryId: [18,19,20] } })}>Cosmétiques Pharmaceutique & Parapharmaceutique</a></li>
                                              <li><a  onClick={() => navigate('/Products', { state: { categoryId: [21,22,23,24,25,26] } })}>Confiseries & Cuisines</a></li>
                                          </ul> 
                                             )}                                        
                                    </div>                   
                                                                   
                                     
                                                                                                			
                           		</div>
                    		</div>
                       </div>
                 </div>
            </div>   
        
           
            
            <div className="section-full p-t80 p-b50 square_shape2 mobile-page-padding">
  <div className="container" style={{ textAlign: 'center' }}>
    <div className="section-head">
      <div className="mt-separator-outer separator-center">
        <div className="mt-separator">
          <h2 className="text-uppercase sep-line-one">
            <span className="font-weight-300 text-primary">Nos</span> Produits
          </h2>
        </div>
      </div>
    </div>

    <div className="filter-wrap p-b30 text-center">
      <ul className="filter-navigation inline-navigation masonry-filter link-style text-uppercase">
  <li className={selectedCategory === 0 ? 'active' : ''}>
    <a onClick={(e) => handleCategoryChange(0, e)} style={{ cursor: 'pointer' }} data-hover="Tous">Tous</a>
  </li>
  <li className={selectedCategory === 1 ? 'active' : ''}>
    <a onClick={(e) => handleCategoryChange(1, e)} style={{ cursor: 'pointer' }} data-hover="Lait & Dérivés">Lait & Dérivés</a>
  </li>
  <li className={selectedCategory === 2 ? 'active' : ''}>
    <a onClick={(e) => handleCategoryChange(2, e)} style={{ cursor: 'pointer' }} data-hover="Huiles">Huiles</a>
  </li>
  <li className={selectedCategory === 3 ? 'active' : ''}>
    <a onClick={(e) => handleCategoryChange(3, e)} style={{ cursor: 'pointer' }} data-hover="Plantes aromatiques & médicinales">Plantes aromatiques & médicinales</a>
  </li>
  <li className={selectedCategory === 4 ? 'active' : ''}>
    <a onClick={(e) => handleCategoryChange(4, e)} style={{ cursor: 'pointer' }} data-hover="Valorisation des grains">Valorisation des grains</a>
  </li>
        <li className={selectedCategory === 5 ? 'active' : ''}>
          <a onClick={(e) => handleCategoryChange(5, e)} style={{ cursor: 'pointer' }} data-hover="Cosmétique et parapharmaceutique">Cosmétique et parapharmaceutique</a>
        </li>
        <li className={selectedCategory === 7 ? 'active' : ''}>
          <a onClick={(e) => handleCategoryChange(6, e)} style={{ cursor: 'pointer' }} data-hover="Confiseries et Cuisines">Confiseries et Cuisines</a>
        </li>
      </ul>
    </div>

    <div className="portfolio-wrap mfp-gallery work-grid row clearfix" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center', position: 'relative' }}>
  {loadingProducts ? (
    <div style={{height:"300px"}}>
    <div className="spinner-overlay">
      <div className="spinner"></div>
    </div>
    </div>
  ) : (
    categoryProducts.map((product) => (
      <div 
        key={product.id} 
        className={`masonry-item cat-${product.category} col-lg-2 col-md-3 col-sm-3 m-b30`} 
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between', 
          alignItems: 'center',
          height: 'auto', 
        }}
        onClick={() => {
          navigate(detailsPage, { state: { productId: product.id } });
          scrollToTop();
        }}
      >
        <div className="project-classic" style={{ flex: '1 1 auto' }}>
          <div className="mt-box">
            <div className="mt-thum-bx img-center-icon mt-img-overlay2" style={{ backgroundColor: '#f8f8f8' }}>
              <div style={{ height: '200px', backgroundColor: '#FFF' }}>
                {isNewProduct(product.created_at) && (
                  <div className="discount">Nouvauté</div>
                )}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <img 
                    src={`data:image/png;base64,${product.photo_data_list[0].data}`} 
                    style={{
                      maxHeight: '90%', 
                      maxWidth: '90%', 
                      width: 'auto',
                      height: 'auto',
                      objectFit: 'contain',
                      display: 'block', 
                      padding: '5px',
                    }} 
                    alt={product.name} 
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-info p-t20" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
            <h6 className="mt-title m-b20 m-t0" style={itemTitleStyle}>
              {product.name}
            </h6>

            <div style={{ marginTop: 'auto', textAlign: 'center' }}>
              {!product.price ? (
                <p>Prix sur devis</p>
              ) : (
                <p>{product.price.toFixed(3)} TND</p>
              )}
            </div>

            <a 
              onClick={() => {
                navigate(detailsPage, { state: { productId: product.id } });
                scrollToTop();
              }} 
              className="site-button-link"
              style={{ textAlign: 'center' }}
            >
              Voir plus <i className="fa fa-angle-right arrow-animation"></i>
            </a>
          </div>
        </div>
      </div>
    ))
  )}
</div>


    {token ? (
      <a onClick={() => navigate('/ProductsAdmin')} className="site-button-link" style={{fontSize: '18px', cursor: 'pointer'}} data-hover="Read More">
        Voir tous <i className="fa fa-angle-right arrow-animation"></i>
      </a>
    ) : (
      <a onClick={() => navigate('/Products')} className="site-button-link" style={{fontSize: '18px', cursor: 'pointer'}} data-hover="Read More">
        Voir tous <i className="fa fa-angle-right arrow-animation"></i>
      </a>
    )}
  </div>
</div>
 

        </div>
       
        <div className="section-full p-tb80 bg-gray inner-page-padding">
            
            <div className="container-fluid">
                <div className="section-content">   
                <div className="section-head">
        <div className="mt-separator-outer separator-center">
          <div className="mt-separator">
            <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Conventionnés</span> Avec</h2>
          </div>
        </div>
      </div>         <PartnersCarousel2/>
                 </div>
             </div>
         </div>
        <div className="section-full p-tb80  inner-page-padding">
            
                <div className="container-fluid">
                    <div className="section-content">   
                    <div className="section-head">
						<div className="mt-separator-outer separator-center">
							<div className="mt-separator">
								<h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Ils Nous Ont Fait</span> Confiance</h2>
							</div>
						</div>
					</div>         <PartnersCarousel/>
                     </div>
                 </div>
             </div>           

       







   

       

		<button className="scroltop"  onClick={scrollToTop}
><span className="fa fa-angle-up  relative" id="btn-vibrate"></span></button>
        
     
    </div>

      </div>
      </div>
      
    );
}
export default Home;