import React, { useState, useEffect } from "react";
import Loading from './Loading';

function Manhalet() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
        <div className="section-full p-tb80 inner-page-padding">
        <div className="container">
            <div className="project-detail-outer">
                <div className="m-b0">
                    <div className="row">
                        <div className="col-md-4 col-sm-4">
                            <div className="project-detail-pic m-b30">
                                <div className="mt-media">
                                    <img src="assets/images/projects/portrait/proj15.jpg" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="project-detail-pic m-b30">
                                <div className="mt-media">
                                    <img src="assets/images/projects/portrait/proj16.jpg" alt=""/>
                                </div>
                            </div>                        	
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="project-detail-pic m-b30">
                                <div className="mt-media">
                                    <img src="assets/images/projects/portrait/proj17.jpg" alt=""/>
                                </div>
                            </div>                        	
                        </div>                                
                    </div>
                </div> 
                
                <div className="m-b30">
                    <div className="row2">
                        <div className="col-md-4">
                            <div className="product-block">
                                <div className="row2">
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Date</h4>
                                    <p>Décembre, 2020</p>
                                  </div>
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Client</h4>
                                    <p>Manhalet Hela</p>
                                   </div>
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Type de Projet</h4>
                                    <p>Production et Valorisation de Miel Artisanal</p>
                                  </div>
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Localisation</h4>
                                    <p>Borj el amri</p>
                                  </div>                                          
                              </div>
                            </div>  
                        </div>
                        
                        <div className="col-md-8">
    <div className="mt-box">
        <div className="mt-thum-bx2 mt-img-overlay1 mt-img-effect yt-thum-box">
            <iframe 
                src="https://www.facebook.com/plugins/video.php?href=https://www.facebook.com/watch/?v=948362932755590"
                width="100%" 
                height="auto" 
                title="Facebook Video"
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none'}}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
            </iframe>
        </div>
    </div>                   
</div>
                   
                    </div>
                </div>                         
                
                                   

                <div className="project-detail-containt">
                    <div className="bg-white text-black">
                        <h3 className="font-weight-600">Projet de Production et Valorisation de Miel Artisanal</h3>
                        <p> Ce projet se consacre à la production et à la valorisation de miel artisanal de haute qualité, en utilisant des méthodes apicoles traditionnelles et respectueuses de l'environnement. Grâce aux équipements fournis par notre entreprise, les apiculteurs locaux peuvent extraire et conditionner le miel de manière efficace, tout en préservant ses propriétés naturelles.</p>
                        <p>Le projet vise à promouvoir le miel local, à soutenir les apiculteurs en améliorant leur productivité et leur revenu, et à offrir aux consommateurs un produit pur et authentique, directement issu de la richesse florale de la région.</p>
                        
                        
                        <div className="m-b0">
                            <div className="mt-divider divider-1px  bg-black"><i className="icon-dot c-square"></i></div>
                        </div>                                
                    </div>
                </div>
              
            </div>
        </div>
    </div>
      
</div>
)}
</div>)
}

export default Manhalet;
