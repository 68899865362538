import React, { useState, useEffect,useRef } from 'react';
import { useLocation , useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import ClipLoader from 'react-spinners/ClipLoader'
import Loading from './Loading';
import { Helmet } from 'react-helmet';


function ProductDetailsAdmin() {
  const [loading, setLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const productId = location.state?.productId;
  const productIdRef = useRef(productId);
  const token = Cookies.get('token');
  const [productDetails, setProductDetails] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [review, setReview] = useState('');
  const [editName,setEditName]=useState("");
  const [showNameForm, setShowNameForm] = useState(false);
  const [editPrice,setEditPrice]=useState("");
  const [showPriceForm, setShowPriceForm] = useState(false);
  const [editDescription,setEditDescription]=useState("");
  const [showDescriptionForm, setShowDescriptionForm] = useState(false);
  const [rating, setRating] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [processingImages, setProcessingImages] = useState(false);
 

  const fetchReviews = async () => {
    try {
      if (productId) {
        const response = await axios.get(`${baseUrl}/get_reviews/${productId}`);
        setReviews(response.data);
      }
    } catch (error) {
      console.error('Error retrieving reviews:', error);
    }
  };

  

  const handleRatingChange = (value) => {
    setRating(value);
  };
  const calculateAverageRating = () => {
    if (reviews.length === 0) return 0;

    const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
    return Math.round(totalRating / reviews.length);
  };

  const handleSubmitReview = async (e) => {
    e.preventDefault();
  
    const reviewData = {
      productId,
      name,
      title,
      review,
      rating,
    };
  
    try {
      const response = await axios.post(`${baseUrl}/save_review`, reviewData);
  
      if (response.status === 201) {
        setName('');
        setTitle('');
        setReview('');
        setRating(0);
      }
    } catch (error) {
      console.error('Error submitting review:', error);
    }
    fetchReviews();
  };
  
  const fetchProductDetails = async () => {
    try {
      if (productId) {
        const response = await axios.get(`${baseUrl}/get_product/${productId}`);
        setProductDetails(response.data);
        document.title = response.data.name;
      }
    } catch (error) {
      console.error('Error retrieving product:', error);
    }
  };   
  useEffect(() => {
    if (!token) {
      navigate('/login'); 
      return;
    }
    fetchProductDetails();
  }, [productId]);
  const itemTitleStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  };
  useEffect(() => {
    const fetchRelatedProducts = async () => {
      try {
        if (productDetails.category_id) {
          const response = await axios.post(`${baseUrl}/get_products`, { category_id: productDetails.category_id });
          
          const sortedProducts = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          
          setRelatedProducts(sortedProducts.slice(0, 6));
        }
      } catch (error) {
        console.error('Error fetching related products:', error);
      }
      finally{
        setLoading(false);
      }
    };
    
    fetchRelatedProducts();
  }, [productDetails.category_id]);


  useEffect(() => {
    fetchReviews();
     
  }, [productId]);



     const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth', 
        });
      };

        
  const toggleEdit = (productDetails, field) => {
    if (field === "name") {
      setShowNameForm((prevShowForm) => !prevShowForm);
      setEditName(productDetails.name);
    } else if (field === "description") {
      setShowDescriptionForm((prevShowForm) => !prevShowForm);
      setEditDescription(productDetails.description);
    } else if (field === "price") {
      setShowPriceForm((prevShowForm) => !prevShowForm);
      setEditPrice(productDetails.price);
    }
  }

const handleChange = (e) => {
  const { name, value } = e.target;
  if (name === 'editName') {
    setEditName(value);
  }
  if (name === 'editPrice') {
    setEditPrice(value === "" || value === "0" ? null : value);
  }
  if (name === 'editDescription') {
    setEditDescription(value);
  }
};
const handleKeyDown = (e) => {
  if (e.key === 'Enter') {
  }
};
  const handleSubmit = async (e, field) => {
    e.preventDefault();
    const currentProductId = productIdRef.current;
  
   
  
    try {
      const response = await axios.put(
        `${baseUrl}/edit_product/${currentProductId}`,
        {name:editName,description:editDescription,price:editPrice}
      );
      setProductDetails(response.data)
      
      setShowNameForm(false);
      setShowPriceForm(false);
      setShowDescriptionForm(false);
    } catch (err) {
      console.error(err);
    }
  };
  const handleDeleteProduct = async (productId) => {
    try {
      const response = await axios.delete(`${baseUrl}/delete_product/${productId}`);
      if (response.status === 200) {
        navigate('/ProductsAdmin');
      }
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };


  const handleAddPhoto = async () => {
    try {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.multiple = true;
  
      input.onchange = async (e) => {
        const files = Array.from(e.target.files);
        setProcessingImages(true);
  
        const processedDataURIs = [];
  
        for (const file of files) {
          const reader = new FileReader();
          reader.onload = async (event) => {
            const base64Image = event.target.result; 
  
            processedDataURIs.push(base64Image); 
              if (processedDataURIs.length === files.length) {
              try {
                const uploadResponse = await axios.post(`${baseUrl}/add_product_photo/${productId}`, {
                  image_data_list: processedDataURIs,
                });
  
                fetchProductDetails(); 
                setProcessingImages(false);
              } catch (error) {
                console.error('Error uploading photos:', error.response?.data || error);
                setProcessingImages(false);
              }
            }
          };
  
          reader.readAsDataURL(file); 
        }
      };
  
      input.click(); 
    } catch (error) {
      console.error('Error adding photo:', error);
      setProcessingImages(false);
    }
  };
  
  
  const handleDeletePhoto = async (photoId) => {
    try {
      const response = await axios.delete(`${baseUrl}/delete_product_photo/${photoId}`);
      if (response.status === 200) {
        fetchProductDetails(); 
      }
    } catch (error) {
      console.error('Error deleting photo:', error);
    }
  };

  const handleDeleteReview = async (reviewId) => {
    try {
      const response = await axios.delete(`${baseUrl}/delete_product_review/${reviewId}`);
      if (response.status === 200) {
        fetchReviews(); 
      }
    } catch (error) {
      console.error('Error deleting review:', error);
    }
    window.location.reload(); 
  };
  
  
    return(
      <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
      <div>
      {loading ? (
        <Loading />
      ) : (
        <div>

{productDetails? ( 
<div>
        <div id="product" className="section-container p-t-20" style={{marginTop:"50px"}}>
       
            <div className="container">
           
                <ul className="breadcrumb m-b-10 f-s-12">
                    <li><a href="/">Acceuil</a></li>
                  <li><a href="/ProductsAdmin">Produits</a></li>
                  <li>
  <a 
    onClick={() => navigate('/ProductsAdmin', { state: { categoryId: productDetails?.category_id } })} 
    style={{ textTransform: 'capitalize', cursor: 'pointer' }}
  >
    {productDetails?.category_name 
      ? productDetails.category_name.includes('-') 
        ? productDetails.category_name.split('-').map((part, index) => (
            <>
              {index > 0 && <span style={{ color: '#ccc' }}>  /  </span>}
              {part}
            </>
          ))
        : productDetails.category_name
      : 'Loading...' 
    }
  </a>
</li>
 <li className="active" style={{textTransform: 'capitalize'}}>{productDetails.name}</li>
                </ul>
              
                <div className="product">
                   
                <div className="product-detail">
      <div className="product-image">
        <div className="product-thumbnail">
          <ul className="product-thumbnail-list">
            {productDetails && productDetails.photo_data_list && (
              productDetails.photo_data_list.map((photoData, index) => (
                <li key={index}>
                  <a  data-click="show-main-image" data-url={`data:image/png;base64,${photoData.data}`}>
                    <img src={`data:image/png;base64,${photoData.data}`} alt={`Product ${index + 1}`} />
                  </a>
                  <div className='item-actions'>
                  <button onClick={() => handleDeletePhoto(photoData.id)}>
                    <i className="fa fa-trash-o"></i>
                  </button>
                  </div>
                </li>
              ))
            )}
          </ul>
          <div className='item-actions'>
          <button  onClick={()=>handleAddPhoto()}>
            <i className="fa fa-plus"></i> Ajouter des photos
          </button>
          </div>
        </div>
        <div className="product-main-image" data-id="main-image">
          {productDetails && productDetails.photo_data_list && (
            <img src={`data:image/png;base64,${productDetails.photo_data_list[0].data}`} alt="" />
          )}
        </div>
      </div>
      <div className="product-info">
  <div className="product-info-header" style={{ position: 'relative' }}>
    <h1 className="product-title" style={{ color: "#000", display: 'flex', alignItems: 'center' }}>
      {productDetails.name}
      <a  className="m-l-5" onClick={() => toggleEdit(productDetails, "name")}>
        <i className="fa fa-pencil-square-o" style={{ color: '#000', marginLeft: '5px' }}></i>
      </a>
      {showNameForm && (
        <input
          onChange={(e) => handleChange(e, "edit")}
          type="text"
          name="editName"
          id="name"
          placeholder='Nom'
          value={editName ? editName : productDetails.name}
          className="form-control"
          style={{ marginLeft: '10px' }}
        />
      )}
      <button
        onClick={() => handleDeleteProduct(productDetails.id)}
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          color: '#000'
        }}
      >
        <i className="fa fa-trash-o"></i>
      </button>
    </h1>
  </div>

  <div className="product-warranty">
    <div className="pull-right">Disponibilité : <p style={{ color: 'green', display: 'inline-block' }}>En stock</p></div>
    <div><b>1 An</b> de garrantie</div>
    <div className="review-star">
      {Array.from({ length: 5 }).map((_, index) => (
        <i
          key={index}
          className={`fa ${index < calculateAverageRating() ? 'fa-star active' : 'fa-star-o'}`}
        ></i>
      ))}
    </div>
  </div>
  <div className="product-warranty">
                                <b>Réference :</b> {productDetails.reference}
                            </div>

  <div className="product-warranty">
    <div className="pull-right" style={{ marginTop: '-10px' }}>Marque : <p style={{ display: 'inline-block', textTransform: 'uppercase' }}>{productDetails.brand}</p></div>
  </div>

  <div className="product-purchase-container">
    <div className="product-price" style={{ display: 'flex', alignItems: 'center' }}>
    <div className="price">
  {productDetails.price != null ? `${productDetails.price.toFixed(3)} TND` : 'Prix'}
</div>
      <a  className="m-l-5" onClick={() => toggleEdit(productDetails, "price")}>
        <i className="fa fa-pencil-square-o"></i>
      </a>
      {showPriceForm && (
        <input
          onChange={(e) => handleChange(e, "edit")}
          type="text"
          name="editPrice"
          id="price"
          placeholder="Prix"
          value={editPrice !== null ? editPrice : ""}
          className="form-control"
          style={{ marginLeft: '10px' }}
        />
      )}
    </div>
  </div>

  <div style={{ height: '150px' }}>
  </div>
</div>

                       
                    </div>
                   
                    <div className="product-tab" style={{marginTop:'-10px'}}>
                        <ul id="product-tab" className="nav nav-tabs">
                            <li className="active"><a href="#product-desc" data-toggle="tab">Description</a></li>
                            <li className=""><a href="#product-reviews" data-toggle="tab">Avis ({reviews.length})</a></li>
                        </ul>
                    
                        <div id="product-tab-content" className="tab-content">
                            
                            <div className="tab-pane fade active in" id="product-desc">
                               
                            
                               
                                <div className="product-desc">
                                   
                                    <div className="desc">
                                        <h4 style={{textTransform: 'capitalize'}}>{productDetails.name}</h4>
                                        <p>
                                        {productDetails && productDetails.description &&
        productDetails.description.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}   <a  className="m-l-5" style={{color:"#000"}} onClick={() => toggleEdit(productDetails, "description")}>            <i className="fa fa-pencil-square-o"></i>
</a>
{showDescriptionForm && (
        <textarea
          onChange={(e) => handleChange(e, 'edit')}
          onKeyDown={handleKeyDown}
          name="editDescription"
          id="description"
          placeholder="Description"
          value={editDescription || productDetails.description}
          className="form-control"
        />
      )}                                      </p>
                                        <button className="site-button btn-effect" onClick={(e) => handleSubmit(e, productId)}>Enregistrer</button>

                                    </div>
                                </div>
                               
                              
                                
                               
                               
                            </div>
                           
                          
                            <div className="tab-pane fade" id="product-reviews">
                               
                                <div className="row row-space-30">
                               
                                <div className="col-md-7">
        {reviews.map((review) => (
          <div className="review" key={review.id}>
            <div className="review-info">
              <div className="review-icon"><img src={`assets2/img/user/user.jpg`} alt="" /></div>
              <div className="review-rate">
                <ul className="review-star">
                  {Array.from({ length: 5 }).map((_, index) => (
                    <li key={index} className={index < review.rating ? 'active' : ''}><i className="fa fa-star"></i></li>
                  ))}

                </ul>
                ({review.rating}/5)
               
                <div className='item-actions'>
                <button onClick={() => handleDeleteReview(review.id)}>
                    <i className="fa fa-trash-o"></i>
                  </button>
                  </div>
              </div>
              <div className="review-name"style={{textTransform: 'capitalize'}}>{review.name}</div>
              <div className="review-date">{review.date}</div>
            </div>
            <div className="review-title" style={{textTransform: 'capitalize'}}>{review.title}</div>
            <div className="review-message">{review.message}</div>
           
          </div>
        ))}
      </div>
                                    <div className="col-md-5">
                                     
                                    <div className="review-form">
            <form onSubmit={handleSubmitReview}>
              <h2>Donner votre avis</h2>
              <div className="form-group">
                <label htmlFor="name">Nom <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="title">Titre <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="review">Avis <span className="text-danger">*</span></label>
                <textarea
                  className="form-control"
                  rows="8"
                  id="review"
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="form-group">
                <label htmlFor="rating">Évaluation <span className="text-danger">*</span></label>
                <div className="rating rating-selection" data-rating="true" data-target="rating">
                  {[1, 2, 3, 4, 5].map((value) => (
                    <i
                      key={value}
                      className={`fa fa-star${rating >= value ? '' : '-o'}`}
                      data-value={value}
                      onClick={() => handleRatingChange(value)}
                    ></i>
                  ))}
                  <span className="rating-comment">
                    <span className="rating-comment-tooltip">Cliquez pour évaluer</span>
                  </span>
                </div>
              </div>
              <button type="submit" className="btn btn-inverse btn-lg">
                Enregistrer
              </button>
            </form>
          </div>
                                      
                                    </div>
                                    
                                </div>
                               
                            </div>
                           
                        </div>
                     
                    </div>
                   
                </div>
                
                <h4 className="m-b-15 m-t-30">Articles similaires</h4>
                <div className="row row-space-10">
        {relatedProducts.slice(0, 6).map((product, index) => (
          <div className="col-md-2 col-sm-4" key={index}>
            <div className="item item-thumbnail">
            <a
          onClick={() => {
            navigate('/ProductDetailsAdmin', { state: { productId: product.id } });
            scrollToTop();
          }}
          className="item-image"
        >
                <img src={`data:image/png;base64,${product.photo_data_list[0].data}`} alt="" />
                <div className="discount">Nouvauté</div>
              </a>
              <div className="item-info">
                <p className="item-desc">
                  {product.brand}
                </p>
                <h4 className="item-title" style={itemTitleStyle}>
                <a
           onClick={() => {
            navigate('/ProductDetailsAdmin', { state: { productId: product.id } });
            scrollToTop();
          }}
          className="item-image" style={{textTransform: 'capitalize'}}>{product.name}</a>
                </h4>
                <div className="item-price">{product.price !== null ? (
              <div className="price">{product.price.toFixed(3)} TND</div>
            ) : (
              <div>prix sur devis</div>
            )}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
              
            </div>
          
        </div>


       
                  <button className="scroltop"  onClick={scrollToTop}
          ><span className="fa fa-angle-up  relative" id="btn-vibrate"></span></button>
                  
               
          </div>
    ):(
           
          <div className="loading-area">
              <div className="loading-box"></div>
              <div className="loading-pic">
                  <div className="cssload-loader">Chargement</div>
              </div>
        
        
</div>)}
{processingImages && (
        <div className="loading-overlay">
        <div className="loading-indicator">
          <ClipLoader color="#F5BF23" loading={processingImages} size={50} />
        </div>
      </div>
      )}
        </div>
      )}
      </div>
      </>
    );
}
export default ProductDetailsAdmin