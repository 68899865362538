import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function FavProducts() {
  const navigate = useNavigate();
  document.title = "Favoris";

  const [fav, setFav] = useState(
    JSON.parse(sessionStorage.getItem("fav")) || []
  );

  const handleRemoveFavItem = (index) => {
    const updatedFav = [...fav];
    updatedFav.splice(index, 1);
    setFav(updatedFav);
    sessionStorage.setItem("fav", JSON.stringify(updatedFav));
    if (updatedFav.length === 0) {
      navigate('/Products');
    }
    window.location.reload();
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      {fav.length > 0 ? (
        <div>
          <div id="search-results" className="section-container bg-silver">
            <div className="container">
              <div className="search-container">
                <div className="search-content" style={{ width: '100%' }}>
                  <div className="search-toolbar">
                    <div className="row">
                      <div className="col-md-6">
                        <h4>{fav.length} produit(s) dans votre liste de favoris</h4>
                      </div>
                    </div>
                  </div>

                  <div className="search-item-container">
                    <div className="item-row">
                        {fav.map((product, index) => (
                          <div className="item item-thumbnail" key={product.id}>
                            <div className="item-actions">
                              <button onClick={() => handleRemoveFavItem(index)}>
                                <i className="fa fa-trash-o"></i>
                              </button>
                            </div>
                            <a
                              onClick={() => navigate('/ProductDetails', { state: { productId: product.id } })}
                              className="item-image"
                            >
                              <img
                                src={`data:image/png;base64,${product.image}`}
                                alt={product.name}
                              />
                            </a>
                            <div className="item-info">
                              <p className="item-desc">{product.brand}</p>
                              <h4
                                className="item-title"
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                <a href={`/ProductDetails/${product.id}`} style={{ textDecoration: 'none' }}>
                                  {product.name}
                                </a>
                              </h4>
                              <div className="item-price">
                                {product.price !== null ? (
                                  <div className="price" style={{ fontWeight: 'bold' }}>
                                    {product.price.toFixed(3)} TND
                                  </div>
                                ) : (
                                  <div>prix sur devis</div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>

          <button className="scroltop" onClick={scrollToTop}>
            <span className="fa fa-angle-up relative" id="btn-vibrate"></span>
          </button>
        </div>
      ) : (
        <div className="loading-area">
          <div className="loading-box"></div>
          <div className="loading-pic">
            <div className="cssload-loader">Chargement</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FavProducts;
