import {React} from "react"; 
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";



export default function Footer(){
const navigate = useNavigate();
const token = Cookies.get("token");
     

return(
    <footer className="site-footer footer-large  footer-light	footer-wide">
                      
    <div className="footer-top overlay-wraper">
        <div className="overlay-main"></div>
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6">  
                    <div className="widget widget_about">
                        <div className="logo-footer clearfix p-b15">
                            <a href="/"><img src="assets/images/logo-mafi.png" alt=""/></a>
                        </div>
                        <p className="max-w400">Imaginons ensemble, réalisons ensemble.<br/> Votre vision devient notre mission!</p>                                
                        
                        <ul className="social-icons  mt-social-links">
                            <li><a href="https://www.facebook.com/mafitunisia" className="fa fa-facebook" target="_blank" rel="noopener noreferrer"></a></li>
                            <li><a href="https://www.linkedin.com/company/maison-de-fournitures-industrielles-mafi/mycompany/" className="fa fa-linkedin" target="_blank" rel="noopener noreferrer"></a></li>
                            <li><a href="https://www.youtube.com/channel/UCrC3yD6IewWFZbXKNW2YiUg" className="fa fa-youtube" target="_blank" rel="noopener noreferrer"></a></li>

                        </ul>                                     
                    </div>
                </div> 
                
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="widget widget_address_outer">
                        <h4 className="widget-title">Contacter nous</h4>
                        <ul className="widget_address">
                        <li> Rue Taieb Mhiri, Khaznadar, Tunis, Tunisia </li>
                            <li><a href="mailto:mafi@orange.tn">mafi@orange.tn</a></li>
                            <li><a href="tel:+21671221100">(+216) 71 221 100</a></li>
                            <li><a href="tel:+21671516321">(+216) 71 516 321</a></li>
                        </ul>
                   
                    </div>                                              	
                </div>                          
                
                <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                    <div className="widget widget_services inline-links">
                        <h4 className="widget-title">Liens utiles</h4>
                        <ul>
                            <li><a href="/">Acceuil</a></li>
                            <li><a href="/About">A propos</a></li>
                            {token? (<li><a onClick={() => navigate('/ProductsAdmin')} style={{cursor:'pointer'}}>Produits</a></li>
                            ):(
                             <li><a onClick={() => navigate('/Products')} style={{cursor:'pointer'}}>Produits</a></li> 
                            )}
                            <li><a href="/Contact">Contact </a></li>
                        </ul>
                    </div>                           
                </div>
                
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="widget recent-posts-entry-date">
                        <h4 className="widget-title">Nous visiter</h4>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3194.5978823947034!2d10.11776317520087!3d36.804190572245986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd324bd13ea205%3A0x1d5d98e81371c5d!2sMAFI!5e0!3m2!1sfr!2stn!4v1688588906978!5m2!1sfr!2stn" width="300" height="225" style={{border:'0',borderRadius:'10px', boxShadow:'0 4px 8px rgba(0, 0, 0, 0.3)'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                    </div>                          
                </div>

            </div>
        </div>  
    </div>
    <div className="footer-bottom overlay-wraper">
    <div className="overlay-main"></div>
    <div className="container">
    <div className="row d-flex justify-content-between align-items-center">
       
        <div className="mt-footer-bot-center">
        <span className="copyrights-text">© {new Date().getFullYear()} MAFI</span>
        </div>
    </div>
</div>

</div>

</footer>

);}