import React, { useState, useEffect } from "react";
import Loading from './Loading';

function Oryx() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
        <div className="section-full p-tb80 inner-page-padding">
        <div className="container">
            <div className="project-detail-outer">
                <div className="m-b0">
                    <div className="row">
                        <div className="col-md-4 col-sm-4">
                            <div className="project-detail-pic m-b30">
                                <div className="mt-media">
                                    <img src="assets/images/projects/portrait/proj8.jpg" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="project-detail-pic m-b30">
                                <div className="mt-media">
                                    <img src="assets/images/projects/portrait/proj9.jpg" alt=""/>
                                </div>
                            </div>                        	
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="project-detail-pic m-b30">
                                <div className="mt-media">
                                    <img src="assets/images/projects/portrait/proj10.jpg" alt=""/>
                                </div>
                            </div>                        	
                        </div>                                
                    </div>
                </div> 
                
                <div className="m-b30">
                    <div className="row2">
                        <div className="col-md-4">
                            <div className="product-block">
                                <div className="row2">
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Date</h4>
                                    <p>Février, 2021</p>
                                  </div>
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Client</h4>
                                    <p>L'oryx bio</p>
                                   </div>
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Type de Projet</h4>
                                    <p>Laboratoire de produits cosmétiques</p>
                                  </div>
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Localisation</h4>
                                    <p>Tunis</p>
                                  </div>                                          
                              </div>
                            </div>  
                        </div>
                        <div className="col-md-8">
    <div className="mt-box">
        <div className="mt-thum-bx2 mt-img-overlay1 mt-img-effect yt-thum-box">
            <iframe 
                src="https://www.facebook.com/plugins/video.php?href=https://www.facebook.com/watch/?v=3920064711394126&rdid=EYIdSiRAtufRdr8rETOFE"
                width="100%" 
                height="auto" 
                title="Facebook Video"
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none'}}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
            </iframe>
        </div>
    </div>                   
</div>
                    </div>
                </div>                         
                                   

                <div className="project-detail-containt">
                    <div className="bg-white text-black">
                        <h3 className="font-weight-600">Projet de Laboratoire de Produits Cosmétiques Naturels - L'Oryx Bio</h3>
                        <p> Le projet "L'Oryx Bio" se concentre sur la création d'un laboratoire de produits cosmétiques naturels, utilisant des matières premières biologiques et locales. Grâce à un équipement de pointe fourni par notre entreprise, ce laboratoire développe des soins de beauté innovants et respectueux de l'environnement. </p>
                        <p>L'Oryx Bio valorise les ressources naturelles tout en répondant à la demande croissante pour des produits cosmétiques sains et éthiques. Ce projet soutient également la création d'emplois qualifiés dans le secteur cosmétique et contribue au développement durable de l'industrie locale.</p>
                        
                        
                        <div className="m-b0">
                            <div className="mt-divider divider-1px  bg-black"><i className="icon-dot c-square"></i></div>
                        </div>                                
                    </div>
                </div>
              
            </div>
        </div>
    </div>
      
</div>
)}
</div>)
}

export default Oryx;
