import React, { useState, useEffect } from "react";
import Loading from './Loading';

function Healthy() {
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
        <div className="section-full p-tb80 inner-page-padding">
        <div className="container">
            <div className="project-detail-outer">
                <div className="m-b0">
                    <div className="row">
                        <div className="col-md-4 col-sm-4">
                            <div className="project-detail-pic m-b30">
                                <div className="mt-media">
                                    <img src="assets/images/projects/portrait/proj11.jpg" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="project-detail-pic m-b30">
                                <div className="mt-media">
                                    <img src="assets/images/projects/portrait/proj12.jpg" alt=""/>
                                </div>
                            </div>                        	
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="project-detail-pic m-b30">
                                <div className="mt-media">
                                    <img src="assets/images/projects/portrait/proj13.jpg" alt=""/>
                                </div>
                            </div>                        	
                        </div>                                
                    </div>
                </div> 
                
                <div className="m-b30">
                    <div className="row2">
                        <div className="col-md-4">
                            <div className="product-block">
                                <div className="row2">
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Date</h4>
                                    <p>Mars, 2021</p>
                                  </div>
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Client</h4>
                                    <p>Healthy & bnin</p>
                                   </div>
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Type de Projet</h4>
                                    <p>Production de Snacks Santé</p>
                                  </div>
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Localisation</h4>
                                    <p>Ben arous, Tunis</p>
                                  </div>                                          
                              </div>
                            </div>  
                        </div>
                        
                        <div className="col-md-8">
                            <div className="mt-box">
                                <div className="mt-thum-bx mt-img-overlay1 mt-img-effect yt-thum-box">
                                    <img src="assets/images/projects/portrait/proj14.jpg" alt=""/>
                                </div>
                            </div>                   	
                        </div>
                    </div>
                </div>                         
                
                                   

                <div className="project-detail-containt">
                    <div className="bg-white text-black">
                        <h3 className="font-weight-600">Projet de Production de Snacks Santé à Base de Dattes</h3>
                        <p>Ce projet vise à développer et commercialiser une gamme de snacks gourmands et naturellement sucrés, élaborés à partir de dattes et conçus par notre nutritionniste. Ces encas, sains et énergétiques, sont fabriqués avec des ingrédients locaux de qualité et sans additifs artificiels, répondant à la demande croissante pour des alternatives sucrées plus saines. </p>
                        <p>En fournissant les équipements nécessaires, nous soutenons la production de ces snacks, qui allient plaisir gustatif et bienfaits nutritionnels, tout en offrant une option pratique et bénéfique pour les consommateurs soucieux de leur santé.</p>
                        
                        
                        <div className="m-b0">
                            <div className="mt-divider divider-1px  bg-black"><i className="icon-dot c-square"></i></div>
                        </div>                                
                    </div>
                </div>
              
            </div>
        </div>
    </div>
      
</div>
)}
</div>)
}

export default Healthy;
