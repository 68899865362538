import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Loading from './Loading';
import { Helmet } from 'react-helmet';


function Register() {
  const [loading, setLoading] = useState(true);
  const [passwordInput, setPasswordInput] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState("");
  const [signatureImage, setSignatureImage] = useState("");
  const [showForm, setShowForm] = useState(false); 
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    setRole("Admin");
    setLoading(false);
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSignatureImage(reader.result); 
      };
      reader.readAsDataURL(file); 
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post(`${baseUrl}/login`, {
        username: "admin",  
        password: passwordInput,
      });

      const { access_token, id } = response.data;
      Cookies.set('token', access_token);
      sessionStorage.setItem('id', id);

      setShowForm(true); 

    } catch (err) {
      setError('Login ou mot de passe invalide(s)');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/register`, { 
        username, 
        password, 
        role,
        signature: signatureImage 
      });
      const token = response.data.access_token;
      Cookies.set("token", token);
      navigate("/Login");
    } catch (error) {
      setError("Invalid credentials");
    }
  };

  return (
     <>
                <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          {!showForm ? (
            <div className="section-full p-t80 p-b50 square_shape2 mobile-page-padding">
              <div className="section-full p-tb80 inner-page-padding">
                <div className="container">
                  <div className="section-content">
                    <div className="row">
                      <div className="col-md-8 col-sm-6">
                        <form onSubmit={handlePasswordSubmit} className="contact-form cons-contact-form">
                          <div className="contact-one m-b30">
                            <div className="section-head">
                              <div className="mt-separator-outer separator-left">
                                <div className="mt-separator">
                                  <h2 className="text-uppercase sep-line-one">
                                    <span className="font-weight-300 text-primary">Enter</span> Admin Password
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <input
                                type="password"
                                value={passwordInput}
                                className="form-control"
                                onChange={(e) => setPasswordInput(e.target.value)}
                                placeholder="Enter admin password"
                                required
                              />
                            </div>
                            <button type="submit" className="site-button btn-effect">Submit</button>
                            {error && <div className="alert alert-danger fade show">{error}</div>}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="section-full p-t80 p-b50 square_shape2 mobile-page-padding">
              <div className="section-full p-tb80 inner-page-padding">
                <div className="container">
                  <div className="section-content">
                    <div className="row">
                      <div className="col-md-8 col-sm-6">
                        <form onSubmit={handleSubmit} className="contact-form cons-contact-form">
                          <div className="contact-one m-b30">
                            <div className="section-head">
                              <div className="mt-separator-outer separator-left">
                                <div className="mt-separator">
                                  <h2 className="text-uppercase sep-line-one">
                                    <span className="font-weight-300 text-primary">Ajouter</span> utilisateur
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                value={username}
                                className="form-control"
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Nom d'utilisateur"
                                required
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="password"
                                value={password}
                                className="form-control form-control-lg"
                                autoComplete="off"
                                placeholder="Mot de passse"
                                onChange={(e) => setPassword(e.target.value)}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                              />
                              {signatureImage && <img src={signatureImage} alt="Signature" style={{ width: "200px", marginTop: "10px" }} />}
                            </div>
                            <div className="text-right">
                              <button type="submit" className="site-button btn-effect">Envoyer</button>
                              {error && <div className="alert alert-danger fade show">{error}</div>}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
    </>
  );
}

export default Register;
