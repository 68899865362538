import React from 'react';

const Loading = () => {
  return (
           
    <div className="loading-area">
        <div className="loading-box"></div>
        <div className="loading-pic">
            <div className="cssload-loader">Chargement</div>
        </div>
      
  
  
</div> )}
export default Loading;
