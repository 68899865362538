import React, { useState, useEffect } from "react";
import Loading from './Loading';

function Bargou() {
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
        <div className="section-full p-tb80 inner-page-padding">
        <div className="container">
            <div className="project-detail-outer">
                <div className="m-b0">
                    <div className="row">
                        <div className="col-md-4 col-sm-4">
                            <div className="project-detail-pic m-b30">
                                <div className="mt-media">
                                    <img src="assets/images/projects/portrait/proj4.jpg" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="project-detail-pic m-b30">
                                <div className="mt-media">
                                    <img src="assets/images/projects/portrait/proj5.jpg" alt=""/>
                                </div>
                            </div>                        	
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="project-detail-pic m-b30">
                                <div className="mt-media">
                                    <img src="assets/images/projects/portrait/proj6.jpg" alt=""/>
                                </div>
                            </div>                        	
                        </div>                                
                    </div>
                </div> 
                
                <div className="m-b30">
                    <div className="row2">
                        <div className="col-md-4">
                            <div className="product-block">
                                <div className="row2">
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Date</h4>
                                    <p>Juin, 2021</p>
                                  </div>
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Client</h4>
                                    <p>Hrayer bargou</p>
                                   </div>
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Type de Projet</h4>
                                    <p>Séchage fruits</p>
                                  </div>
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Localisation</h4>
                                    <p>Bargou</p>
                                  </div>                                          
                              </div>
                            </div>  
                        </div>
                        <div className="col-md-8">
    <div className="mt-box">
        <div className="mt-thum-bx mt-img-overlay1 mt-img-effect yt-thum-box">
            <img src="assets/images/projects/portrait/proj7.jpg" alt="Video Thumbnail" style={{width: '100%', height: 'auto'}} />

        </div>
    </div>                   
</div>

                    </div>
                </div>    
                                     
                
                                   

                <div className="project-detail-containt">
                    <div className="bg-white text-black">
                        <h3 className="font-weight-600">Projet de Séchage de Fruits pour la Valorisation Agricole</h3>
                        <p> Ce projet vise à renforcer la chaîne de valeur agricole en mettant en place une unité de séchage de fruits, équipée de matériel fourni par notre entreprise. En transformant les récoltes locales en fruits séchés de haute qualité, ce projet permet aux producteurs d'ajouter de la valeur à leurs produits, de réduire les pertes post-récolte, et d'accéder à de nouveaux marchés. </p>
                        <p>Il favorise également la création d'emplois dans les communautés rurales et contribue à la diversification des sources de revenus pour les agriculteurs, tout en offrant des produits sains et durables aux consommateurs.</p>
                        
                        
                        <div className="m-b0">
                            <div className="mt-divider divider-1px  bg-black"><i className="icon-dot c-square"></i></div>
                        </div>                                
                    </div>
                </div>
              
            </div>
        </div>
    </div>
      
</div>
)}
</div>)
}

export default Bargou;
