import React, { useState, useEffect } from "react";
import Loading from './Loading';

function Isbst() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
        <div className="section-full p-tb80 inner-page-padding">
        <div className="container">
            <div className="project-detail-outer">
                <div className="m-b30">
                    <div className="row2">
                        <div className="col-md-4">
                            <div className="product-block">
                                <div className="row2">
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Date</h4>
                                    <p>Juin, 2020</p>
                                  </div>
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Client</h4>
                                    <p>ISBST</p>
                                   </div>
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Type de Projet</h4>
                                    <p>Fourniture d'une Fromagerie Pilote de Laboratoire à l'ISBST</p>
                                  </div>
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Localisation</h4>
                                    <p>Sidi thabet</p>
                                  </div>                                         
                              </div>
                            </div>  
                        </div>
                        <div className="col-md-8">
    <div className="mt-box">
        <div className="mt-thum-bx2 mt-img-overlay1 mt-img-effect yt-thum-box">
            <iframe 
                src="https://www.facebook.com/plugins/video.php?href=https://www.facebook.com/watch/?v=1200678230267839&rdid=MOZd20Em2xu1RRC2"
                width="100%" 
                height="auto" 
                title="Facebook Video"
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none'}}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
            </iframe>
        </div>
    </div>                   
</div>
                    </div>
                </div>                         
                
                                   

                <div className="project-detail-containt">
                    <div className="bg-white text-black">
                        <h3 className="font-weight-600">Fourniture d'une Fromagerie Pilote de Laboratoire à l'ISBST - Sidi Thabet</h3>
                        <p>Ce projet consiste en la fourniture par MAFI d'une fromagerie pilote de laboratoire à l'Institut Supérieur de Biotechnologie de Sidi Thabet (ISBST). Cette installation permet aux étudiants et chercheurs de l'institut de développer, tester, et perfectionner des techniques de production fromagère à petite échelle. </p>
                        <p>En combinant l'enseignement théorique avec une pratique concrète en laboratoire, ce projet vise à renforcer les compétences en biotechnologie alimentaire, tout en contribuant à l'innovation et à la recherche dans le domaine des produits laitiers.</p>
                        
                        
                        <div className="m-b0">
                            <div className="mt-divider divider-1px  bg-black"><i className="icon-dot c-square"></i></div>
                        </div>                                
                    </div>
                </div> 
            </div>
        </div>
    </div>
      
</div>
)}
</div>)
}

export default Isbst;
