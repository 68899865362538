import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from './Loading';
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Devis() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all'); 
  const [sortOrder, setSortOrder] = useState('desc'); 
  const capitalize = (str) => 
    str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');

  document.title = "Demandes de devis";
  const [devisRequests, setDevisRequests] = useState([]);

  useEffect(() => {
    if (!token) {
      navigate('/login'); 
      return;
    }
      const fetchDevisRequests = async () => {
        try {
          const response = await axios.get(`${baseUrl}/get_devis_requests`);
          setDevisRequests(response.data);
        } catch (error) {
          console.error("Error retrieving devis", error);
        } finally {
          setLoading(false);
        }
      };
      fetchDevisRequests();
    }, []);

    const handleRowClick = async (devis) => {
      console.log("Row clicked:", devis);
  
      try {
        const response = await axios.post(`${baseUrl}/get_devis_details`,{
          created_at_str: devis.created_at,
          client_email: devis.client_email
        });
  
        console.log("Devis Details Response:", response.data);
        if (!response.data || response.data.length === 0) {
          console.error("No devis details found");
          return;
        }
  
        const devisIdsArray = response.data.map(devisRequest => devisRequest.id);
        if (devisIdsArray.length === 0) {
          console.error("No valid devis IDs available");
          return;
        }
  
        const response2 = await axios.post(`${baseUrl}/generate-devis-number`, 
          { devis_ids: devisIdsArray }, 
          { headers: { "Content-Type": "application/json" } }
        );
  
        console.log("Generated Devis Number:", response2.data);
        navigate("/DevisDetails", {
          state: {
            created_at: devis.created_at,
            client_email: devis.client_email,
            devisNumber: response2.data.devis_number
          }
        });
      } catch (error) {
        console.error("Error generating devis number:", error);
      }
    };
  

  const filteredDevisRequests = devisRequests.filter(devis => {
    const matchesSearch = devis.client_name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = filterStatus === 'all' || (filterStatus === 'en-cours' && (devis.price_is_null || devis.tva_is_null)) || (filterStatus === 'done' && !(devis.price_is_null || devis.tva_is_null));
    return matchesSearch && matchesStatus;
  });

  const sortedDevisRequests = filteredDevisRequests.sort((a, b) => {
    if (sortOrder === 'asc') {
        return new Date(a.created_at) - new Date(b.created_at);
    }
    return new Date(b.created_at) - new Date(a.created_at);
  });

  return (
    <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="page-content">
            <div className="mt-bnr-inr overlay-wraper bg-parallax bg-bottom-center"  data-stellar-background-ratio="0.5"  style={{backgroundImage: "url(assets/images/banner/39.jpg)"}}>
                <div className="overlay-main bg-black opacity-07"></div>
                <div className="container">
                    <div className="mt-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <h1 className="m-b0">Demandes de devis</h1>
                            </div>
                        </div>                    
                            <div>
                                <ul className="mt-breadcrumb breadcrumb-style-2">
                                    <li><a href="/">Acceuil</a></li>
                                    <li>Devis</li>
                                </ul>
                            </div>
                    </div>
                </div>
            </div>
         
            <div className="section-full p-tb80 inner-page-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 col-sm-8">   
                            <div className="section-content m-b50">
                                <div className="section-head">
                                    <div className="mt-separator-outer separator-left">
                                        <div className="mt-separator">
                                            <h2 className="text-black text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Demandes </span> de devis</h2>
                                        </div>
                                    </div>
                                </div>       

                                <div className="mb-4">
                                  <div className="filter-controls">
                                    <input
                                      type="text"
                                      placeholder="Recherche par client"
                                      value={searchTerm}
                                      onChange={(e) => setSearchTerm(e.target.value)}
                                      className="form-control"
                                    />
                                    <select
                                      value={filterStatus}
                                      onChange={(e) => setFilterStatus(e.target.value)}
                                      className="form-control"
                                    >
                                      <option value="all">Tous</option>
                                      <option value="en-cours">En cours</option>
                                      <option value="done">Effectué</option>
                                    </select>
                                    <button
                                      onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                                      className="btn btn-primary"
                                    >
                                      Trier par Date  <i className={`fas fa-arrow-${sortOrder === 'asc' ? 'up' : 'down'}`}></i>
                                    </button>
                                  </div>
                                </div>

                                <div id="no-more-tables">
                                    <table className="table-striped table-condensed cf mt-responsive-table">
                                        <thead className="cf">
                                            <tr>
                                                <th className="numeric">Client</th>
                                                <th>Email</th>
                                                <th className="numeric">Nombre de produits</th>
                                                <th className="numeric">Date</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortedDevisRequests.map((devis, index) => (
                                                <tr key={index} onClick={() => handleRowClick(devis)}>
                                                    <td data-title="Client">{capitalize(devis.client_name)}</td>
                                                    <td data-title="Email">{devis.client_email}</td>
                                                    <td data-title="Nombre de produits" className="numeric">{devis.request_count}</td>
                                                    <td data-title="Date" className="numeric">{devis.created_at}</td>                    
                                                    <td data-title="Status" style={{color: devis.price_is_null || devis.tva_is_null ? 'orange' : 'green'}}>
                                                        {devis.price_is_null || devis.tva_is_null ? 'En cours' : 'Effectué'}
                                                    </td>                        
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>  
                        </div>
                    </div>   
                </div>   
            </div>
        </div>
      )}
    </div>
    </>
  );
}

export default Devis;
