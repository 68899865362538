import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const PartnersCarousel = () => {
  const partners = [
    { logo: '111.png', url: 'https://www.giz.de/en/worldwide/22600.html' },
    { logo: '222.png', url: 'https://www.mercycorps.org/' },
    { logo: '333.png', url: 'https://www.taysir.com.tn/fr/' },
    { logo: '444.png', url: 'https://www.fao.org/home/fr' },
    { logo: '555.png', url: 'https://www.fhi360.org/' },
    { logo: '666.png', url: 'https://islamic-relief.org/' }
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2,
    },
  };

  const partnerLogoStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
    filter: 'grayscale(100%)', 
    transition: 'filter 0.3s ease',
  };

  const partnerItemStyle = {
    padding: '10px', 
  };

  return (
    <Carousel responsive={responsive} autoPlay={true} infinite={true} showArrows={false}>
      {partners.map((partner, index) => (
        <div key={index} style={partnerItemStyle}>
          <a href={partner.url} target="_blank" rel="noopener noreferrer">
            <img
              src={`assets/images/${partner.logo}`}
              alt={`Partner ${index}`}
              style={partnerLogoStyle}
              onMouseOver={(e) => e.currentTarget.style.filter = 'grayscale(0%)'} 
              onMouseOut={(e) => e.currentTarget.style.filter = 'grayscale(100%)'} 
            />
          </a>
        </div>
      ))}
    </Carousel>
  );
};

export default PartnersCarousel;
