import React, { useState, useEffect } from "react";
import Loading from './Loading';

function Projects() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="page-content">
        <div className="mt-bnr-inr overlay-wraper bg-parallax bg-top-center"  data-stellar-background-ratio="0.5"  style={{backgroundImage:"url(assets/images/banner/16.jpg)"}}>
            <div className="overlay-main bg-black opacity-07"></div>
            <div className="container">
                <div className="mt-bnr-inr-entry">
                    <div className="banner-title-outer">
                        <div className="banner-title-name">
                            <h2 className="m-b0" style={{fontSize:"40px"}}><strong>projets réalisés</strong></h2>
                        </div>
                    </div>
                    
                        <div>
                            <ul className="mt-breadcrumb breadcrumb-style-2">
                                <li><a href="/">Acceuil</a></li>
                                <li>Projets réalisés</li>
                            </ul>
                        </div>
                    
                </div>
            </div>
        </div>
        
        <div className="section-full p-tb80 bg-white inner-page-padding">
        <div className="container">
        <div className="portfolio-wrap mfp-gallery news-grid clearfix row ">
                
            <div className="masonry-item2  col-lg-4 col-md-4 col-sm-6">
                    <div className="blog-post blog-grid date-style-2">
                    
                        <div className="mt-post-media mt-img-effect zoom-slow">
                            <a href="/LellaKmar"><img src="assets/images/blog/blog-grid/1.jpg" alt=""/></a>
                        </div>
                        
                        <div className="mt-post-info p-t30">
                    
                            <div className="mt-post-title ">
                                <h4 className="post-title"><a href="/LellaKmar">Lella kmar el baya SMSA</a></h4>
                            </div>
                            
                            <div className="mt-post-meta ">
                                <ul>
                                    <li className="post-date"> <i className="fa fa-calendar"></i>Février<span>2022</span> </li>
                                    <li className="post-author"><i className="fa fa-location-dot"></i>El battan</li>

                                </ul>
                            </div>
                            
                            
                            
                            <div className="clearfix">
                                <div className="mt-post-readmore pull-left">
                                     <a href="/LellaKmar" title="Voir plus" rel="bookmark" className="site-button-link">Voir plus<i className="fa fa-angle-right arrow-animation"></i></a>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>                        
            </div>
            
            <div className="masonry-item2 col-lg-4 col-md-4 col-sm-6">
                <div className="blog-post blog-grid date-style-2">
                    
                    <div className="mt-post-media mt-img-effect zoom-slow">
                        <a href="/Bargou"><img src="assets/images/blog/blog-grid/2.jpg" alt=""/></a>
                    </div>
                    
                    <div className="mt-post-info p-t30">
                
                        <div className="mt-post-title ">
                            <h4 className="post-title"><a href="/Bargou">Hrayer bargou</a></h4>
                        </div>
                        
                        <div className="mt-post-meta ">
                            <ul>
                                <li className="post-date"> <i className="fa fa-calendar"></i>Juin <span>2021</span> </li>
                                <li className="post-author"><i className="fa fa-location-dot"></i><span>Bargou</span> </li>
                            </ul>
                        </div>
                        
                        
                        
                        <div className="clearfix">
                            <div className="mt-post-readmore pull-left">
                                 <a href="/Bargou" title="Voir plus" rel="bookmark" className="site-button-link">Voir plus<i className="fa fa-angle-right arrow-animation"></i></a>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                
            </div>
            
            <div className="masonry-item2  col-lg-4 col-md-4 col-sm-6">
                <div className="blog-post blog-grid date-style-2">
                    
                    <div className="mt-post-media mt-img-effect zoom-slow">
                        <a href="/Oryx"><img src="assets/images/blog/blog-grid/3.jpg" alt=""/></a>
                    </div>
                    
                    <div className="mt-post-info p-t30">
                
                        <div className="mt-post-title ">
                            <h4 className="post-title"><a href="/Oryx">L'oryx bio</a></h4>
                        </div>
                        
                        <div className="mt-post-meta ">
                            <ul>
                                <li className="post-date"> <i className="fa fa-calendar"></i>Février<span>2021</span> </li>
                                <li className="post-author"><i className="fa fa-location-dot"></i>Tunis </li>
                            </ul>
                        </div>
                        
                        
                        
                        <div className="clearfix">
                            <div className="mt-post-readmore pull-left">
                                 <a href="/Oryx" title="Voir plus" rel="bookmark" className="site-button-link">Voir plus<i className="fa fa-angle-right arrow-animation"></i></a>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
            
            <div className="masonry-item2 col-lg-4 col-md-4 col-sm-6">
                <div className="blog-post blog-grid date-style-2">
                    
                    <div className="mt-post-media mt-img-effect zoom-slow">
                        <a href="/Healthy"><img src="assets/images/blog/blog-grid/4.jpg" alt=""/></a>
                    </div>
                    
                    <div className="mt-post-info p-t30">
                
                        <div className="mt-post-title ">
                            <h4 className="post-title"><a href="/Healthy">Healthy & bnin</a></h4>
                        </div>
                        
                        <div className="mt-post-meta ">
                            <ul>
                                <li className="post-date"> <i className="fa fa-calendar"></i>Mars <span>2021</span> </li>
                                <li className="post-author"><i className="fa fa-location-dot"></i>Ben arous </li>
                            </ul>
                        </div>
                        
                        
                        
                        <div className="clearfix">
                            <div className="mt-post-readmore pull-left">
                                 <a href="/Healthy" title="Voir plus" rel="bookmark" className="site-button-link">Voir plus<i className="fa fa-angle-right arrow-animation"></i></a>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
            
            <div className="masonry-item2  col-lg-4 col-md-4 col-sm-6">
                <div className="blog-post blog-grid date-style-2">
                    
                    <div className="mt-post-media mt-img-effect zoom-slow">
                        <a href="/Manhalet"><img src="assets/images/blog/blog-grid/6.jpg" alt=""/></a>
                    </div>
                    
                    <div className="mt-post-info p-t30">
                
                        <div className="mt-post-title ">
                            <h4 className="post-title"><a href="/Manhalet">Manhalet hela</a></h4>
                        </div>
                        
                        <div className="mt-post-meta ">
                            <ul>
                                <li className="post-date"> <i className="fa fa-calendar"></i>Décembre<span>2020</span> </li>
                                <li className="post-author"><i className="fa fa-location-dot"></i>Borj el amri </li>
                            </ul>
                        </div>
                        
                        
                        
                        <div className="clearfix">
                            <div className="mt-post-readmore pull-left">
                                 <a href="/Manhalet" title="Voir plus" rel="bookmark" className="site-button-link">Voir plus<i className="fa fa-angle-right arrow-animation"></i></a>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
            
            <div className="masonry-item2 col-lg-4 col-md-4 col-sm-6">
                <div className="blog-post blog-grid date-style-2">
                    
                    <div className="mt-post-media mt-img-effect zoom-slow">
                        <a href="/Isbst"><img src="assets/images/blog/blog-grid/7.jpg" alt=""/></a>
                    </div>
                    
                    <div className="mt-post-info p-t30">
                
                        <div className="mt-post-title ">
                            <h4 className="post-title"><a href="/Isbst">Projet université ISBST</a></h4>
                        </div>
                        
                        <div className="mt-post-meta ">
                            <ul>
                                <li className="post-date"> <i className="fa fa-calendar"></i>Juin <span>2020</span> </li>
                                <li className="post-author"><i className="fa fa-location-dot"></i>Sidi Thabet </li>
                            </ul>
                        </div>
                        
                        
                        
                        <div className="clearfix">
                            <div className="mt-post-readmore pull-left">
                                 <a href="/Isbst" title="Voir plus" rel="bookmark" className="site-button-link">Voir plus<i className="fa fa-angle-right arrow-animation"></i></a>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
                               

        </div>

    </div>
    </div>
    </div>
      )}
    </div>
  );
}

export default Projects;
