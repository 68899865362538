import React, { useState, useEffect } from 'react';
import { useLocation , useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from './Loading';
import { Helmet } from 'react-helmet';

function ProductDetails() {
  const [loading, setLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const [loadingProducts,setLoadingProducts] = useState(true);
  const productId = location.state?.productId;
  const [productDetails, setProductDetails] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [cart, setCart] = useState(JSON.parse(sessionStorage.getItem("cart")) || []);
  const [fav, setFav] = useState(JSON.parse(sessionStorage.getItem("fav")) || []);
  const [editQuantity, setEditQuantity] = useState(1);
 

  const fetchReviews = async () => {
    try {
      if (productId) {
        const response = await axios.get(`${baseUrl}/get_reviews/${productId}`);
        setReviews(response.data);
      }
    } catch (error) {
      console.error('Error retrieving reviews:', error);
    }
  };


  const handleRatingChange = (value) => {
    setRating(value);
  };
  const calculateAverageRating = () => {
    if (reviews.length === 0) return 0;

    const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
    return Math.round(totalRating / reviews.length);
  };

  const handleSubmitReview = async (e) => {
    e.preventDefault();
  
    const reviewData = {
      productId,
      name,
      title,
      review,
      rating,
    };
  
    try {
      const response = await axios.post(`${baseUrl}/save_review`, reviewData);
  
      if (response.status === 201) {
        setName('');
        setTitle('');
        setReview('');
        setRating(0);
      }
    } catch (error) {
      console.error('Error submitting review:', error);
    }
    fetchReviews();
  };
  
     
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        if (productId) {
          const response = await axios.get(`${baseUrl}/get_product/${productId}`);
          setProductDetails(response.data);

        }
      } catch (error) {
        console.error('Error retrieving product:', error);
      }
      finally{
        setLoading(false);
      }
    };
    
    fetchProductDetails();
  }, [productId]);
  const itemTitleStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  };


  useEffect(() => {
    const fetchRelatedProducts = async () => {
      try {
        if (productDetails.category_id) {
          const response = await axios.post(`${baseUrl}/get_products`, {
            categoryId: productDetails.category_id,  
        });
          const currentDate = new Date();
          const sortedProducts = response.data
            .map((product) => ({
              ...product,
              isNew: Math.ceil((currentDate - new Date(product.created_at)) / (1000 * 60 * 60 * 24)) <= 30,
            }))
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          setRelatedProducts(sortedProducts.slice(0, 6));
          setLoadingProducts(false);
        }
      } catch (error) {
        console.error('Error fetching related products:', error);
      }
    };

    fetchRelatedProducts();
  }, [productDetails.category_id]);


  useEffect(() => {
    fetchReviews();
     
  }, [productId]);



     const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth', 
        });
      };

      const handleAddToCart = () => {
        try {
          const newItem = {
            name: productDetails.name,
            quantity: editQuantity,
            image: productDetails.photo_data_list[0].data,
            id: productDetails.id,
            price: productDetails.price,
            brand: productDetails.brand,
            reference: productDetails.reference
          };
      
          const updatedCart = [...cart, newItem];
          setCart(updatedCart);
      
          // Save updated cart to session storage
          sessionStorage.setItem("cart", JSON.stringify(updatedCart));
      
          const cartUpdatedEvent = new Event("cartUpdated");
          window.dispatchEvent(cartUpdatedEvent);
          window.location.reload();
          
        } catch (e) {
          if (e.name === 'QuotaExceededError') {
            alert('Le stockage de session est plein. Impossible d\'ajouter plus d\'articles à votre panier !"');
          } else {
            console.error('An unexpected error occurred:', e);
          }
        }
      };
      console.log(relatedProducts)
      const handleAddToFav = () => {
        try {
          const isAlreadyFav = fav.some(item => item.id === productDetails.id);
        
          if (isAlreadyFav) {
            const updatedFav = fav.filter(item => item.id !== productDetails.id);
            setFav(updatedFav);
            sessionStorage.setItem("fav", JSON.stringify(updatedFav));
          } else {
            const newItem = {
              name: productDetails.name,
              image: productDetails.photo_data_list[0].data,
              id: productDetails.id,
              price: productDetails.price,
              brand: productDetails.brand
            };
            const updatedFav = [...fav, newItem];
            setFav(updatedFav);
            sessionStorage.setItem("fav", JSON.stringify(updatedFav));
          }
      
          const favUpdatedEvent = new Event("favUpdated");
          window.dispatchEvent(favUpdatedEvent);
          window.location.reload();
        
        } catch (e) {
          if (e.name === 'QuotaExceededError') {
            alert('Le stockage de session est plein. Impossible d\'ajouter plus d\'articles à vos favoris !');
          } else {
            console.error('An unexpected error occurred:', e);
          }
        }
      };
      
    return(
      <div>
      {loading ? (
        <Loading />
      ) : (
        <div>

{productDetails? ( 
  <>
         <Helmet>
                <title>{productDetails.name} | MAFI</title>
                <meta name="description" content={productDetails.description} />
                <meta name="keywords" content={`${productDetails.name}, ${productDetails.brand}, ${productDetails.category}`} />
                <meta property="og:title" content={productDetails.name} />
                <meta property="og:description" content={productDetails.description} />
                <meta property="og:url" content={window.location.href} />
            </Helmet>
 
<div>
        <div id="product" className="section-container p-t-20" style={{marginTop:"50px"}}>
       
            <div className="container">
           
                <ul className="breadcrumb m-b-10 f-s-12">
                    <li><a href="/">Acceuil</a></li>
                    <li><a onClick={() => navigate('/Products')} style={{cursor:'pointer'}}>Produits</a></li>
                    <li>
                    <a 
    onClick={() => navigate('/Products', { state: { categoryId: productDetails?.category_id } })} 
    style={{ textTransform: 'capitalize', cursor: 'pointer' }}
  >
{productDetails?.category_name 
  ? productDetails.category_name.includes('-') 
    ? productDetails.category_name.split('-').map((part, index) => (
        <React.Fragment key={index}>
          {index > 0 && <span style={{ color: '#ccc' }}>  /  </span>}
          {part}
        </React.Fragment>
      ))
    : productDetails.category_name
  : 'Loading...'
}

  </a>
</li>
                    <li className="active" style={{textTransform: 'capitalize'}}>{productDetails.name}</li>
                </ul>
              
                <div className="product">
                   
                    <div className="product-detail">
                        <div className="product-image">
                            
                            <div className="product-thumbnail">
                                <ul className="product-thumbnail-list">
                                {productDetails && productDetails.photo_data_list && (
  productDetails.photo_data_list.map((photoData, index) => (
            <li key={index}>
              <a  data-click="show-main-image" data-url={`data:image/png;base64,${photoData.data}`}>
                <img src={`data:image/png;base64,${photoData.data}`} alt={`Product ${index + 1}`} />
              </a>
            </li>
            ))
            )}
                                </ul>
                            </div>
                          
                            <div className="product-main-image" data-id="main-image">
                                
                            {productDetails && productDetails.photo_data_list &&(
        <img src={`data:image/png;base64,${productDetails.photo_data_list[0].data}`} alt="" />
        
      )}                            </div>
             
                        
                        </div>
                      
                        <div className="product-info">
                            
                            <div className="product-info-header">
                                <h1 className="product-title" style={{color:"#000"}}>{productDetails.name} </h1>
                                                  
                            <button className="header-cart" style={{background:'none',border:'none'}} onClick={handleAddToFav}>

                            {fav.some(item => item.id === productDetails.id) ? (
      <i className="fa fa-heart" style={{color:'#F5BF23'}}></i>
    ) : (
      <i className="fa fa-heart-o"></i>
    )}        
</button>
                            </div>
                            
                            <div className="product-warranty">
                                <div className="pull-right">Disponibilité : <p style={{color:'green',display:'inline-block'}}>En stock</p></div>
                                <div><b>1 An</b> de garrantie</div>
                                <div className="review-star">
          
            {Array.from({ length: 5 }).map((_, index) => (
                <i
                  key={index}
                  className={`fa ${index < calculateAverageRating() ? 'fa-star active' : 'fa-star-o'}`}
                ></i>
              ))}
          
          </div>
                                
                            </div>
                            <div className="product-warranty">
                                <b>Réference :</b> {productDetails.reference}
                            </div>
                            <div className="product-warranty">
                                <div className="pull-right" style={{marginTop:'-10px'}}>Marque : <p style={{display:'inline-block',textTransform:'uppercase'}}>{productDetails.brand}</p></div>
                    
                                
                            </div>
                            <div className="product-purchase-container">
                                
                            <div className="product-price">
           
                            {productDetails.price !== null ? (
  <div className="product-price">
<div className="price">
  <span className="price-value">{productDetails.price.toFixed(3)}</span> TND
</div>

  </div>
) : (
  <div className="product-purchase-container">
  <div className="product-row">
    <button className="site-button btn-effect add-to-cart-button" onClick={handleAddToCart}>
     Ajouter au devis +
    </button>
    <div className="product-details">
      <label htmlFor="quantity-input">Quantité</label>
      <input
        type="number"
        id="quantity-input"
        value={editQuantity}
        onChange={(e) => setEditQuantity(e.target.value)}
        className="quantity-input"
      />
    </div>
  </div>
</div>

)}

</div>
                            </div>
                            
                         
                        </div>
                       
                    </div>
                   
                    <div className="product-tab" style={{marginTop:'-10px'}}>
                        <ul id="product-tab" className="nav nav-tabs">
                            <li className="active"><a href="#product-desc" data-toggle="tab">Description</a></li>
                            <li className=""><a href="#product-reviews" data-toggle="tab">Avis ({reviews.length})</a></li>
                        </ul>
                    
                        <div id="product-tab-content" className="tab-content">
                            
                            <div className="tab-pane fade active in" id="product-desc">
                               
                            
                               
                                <div className="product-desc">
                                   
                                    <div className="desc" >
                                        <h4>{productDetails.name}</h4>
                                        <p>
                                        {productDetails && productDetails.description &&
        productDetails.description.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}                                     </p>
                                    </div>
                                </div>
                               
                              
                                
                               
                               
                            </div>
                           
                          
                            <div className="tab-pane fade" id="product-reviews">
                               
                                <div className="row row-space-30">
                               
                                <div className="col-md-7">
        {reviews.map((review) => (
          <div className="review" key={review.id}>
            <div className="review-info">
              <div className="review-icon"><img src={`assets2/img/user/user.jpg`} alt="" /></div>
              <div className="review-rate">
                <ul className="review-star">
                  {Array.from({ length: 5 }).map((_, index) => (
                    <li key={index} className={index < review.rating ? 'active' : ''}><i className="fa fa-star"></i></li>
                  ))}
                </ul>
                ({review.rating}/5)
              </div>
              <div className="review-name">{review.name}</div>
              <div className="review-date">{review.date}</div>
            </div>
            <div className="review-title">{review.title}</div>
            <div className="review-message">{review.message}</div>
          </div>
        ))}
      </div>
                                    <div className="col-md-5">
                                     
                                    <div className="review-form">
            <form onSubmit={handleSubmitReview}>
              <h2>Donner votre avis</h2>
              <div className="form-group">
                <label htmlFor="name">Nom <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="title">Titre <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="review">Avis <span className="text-danger">*</span></label>
                <textarea
                  className="form-control"
                  rows="8"
                  id="review"
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="form-group">
                <label >Évaluation <span className="text-danger">*</span></label>
                <div className="rating rating-selection" data-rating="true" data-target="rating">
                  {[1, 2, 3, 4, 5].map((value) => (
                    <i
                      key={value}
                      className={`fa fa-star${rating >= value ? '' : '-o'}`}
                      data-value={value}
                      onClick={() => handleRatingChange(value)}
                    ></i>
                  ))}
                  <span className="rating-comment">
                    <span className="rating-comment-tooltip">Cliquez pour évaluer</span>
                  </span>
                </div>
              </div>
              <button type="submit" className="btn btn-inverse btn-lg">
                Enregistrer
              </button>
            </form>
          </div>
                                      
                                    </div>
                                    
                                </div>
                               
                            </div>
                           
                        </div>
                     
                    </div>
                   
                </div>
                
                <h4 className="m-b-15 m-t-30">Articles similaires</h4>
                <div className="row row-space-10">
  {loadingProducts ? (
        <div className="spinner-container"> 
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      </div>
    ) : (
         relatedProducts.slice(0, 6).map((product, index) => (
  <div className="col-md-2 col-sm-4" key={product.id || `product-${index}`}>

            <div className="item item-thumbnail">
            <a
          onClick={() => {
            navigate('/ProductDetails', { state: { productId: product.id } });
            scrollToTop();
          }}
          className="item-image"
        >
                <img src={`data:image/png;base64,${product.photo_data_list[0].data}`} alt="" />
             
                {product.isNew && <div className="discount">Nouvauté</div>}
              
              </a>
              <div className="item-info">
                <p className="item-desc">
                  {product.brand}
                </p>
                <h4 className="item-title" style={itemTitleStyle}>
                <a
           onClick={() => {
            navigate('/ProductDetails', { state: { productId: product.id } });
            scrollToTop();
          }}>{product.name}</a>
                </h4>
                <div className="item-price">{product.price !== null ? (
              <div className="price">{product.price.toFixed(3)} TND</div>
            ) : (
              <div>prix sur devis</div>
            )}</div>
              </div>
            </div>
          </div>
        ))
  )}
      </div>
      
            </div>
          
        </div>


       
                  <button className="scroltop"  onClick={scrollToTop}
          ><span className="fa fa-angle-up  relative" id="btn-vibrate"></span></button>
                  
               
          </div>
          </>
    ):(
           
          <div className="loading-area">
              <div className="loading-box"></div>
              <div className="loading-pic">
                  <div className="cssload-loader">Chargement</div>
              </div>
        
        
</div>)}
        </div>
      )}
      </div>
    );
}
export default ProductDetails