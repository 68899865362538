import React from "react";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";



const Categories = () => {
    const navigate = useNavigate();
    const token = Cookies.get("token");
    const handleCategoryClick = (path, state) => {
        navigate(path, { state });
    };

  return (

<div className="section-full mobile-page-padding p-t80 p-b30 square_shape2" >
                <div className="container">
					
                <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
                    <strong>Nos Spécialités</strong>
                </div>                   

						<div className="section-content">

                             <div className="portfolio-wrap row mfp-gallery product-stamp clearfix">
                                
                                <div className="masonry-item  cat-2 col-md-3 col-sm-6 m-b30">
                                    <div className="mt-box   image-hover-block">
                                        <div className="mt-thum-bx">
                                            <img src="assets/images/projects/portrait/111.jpg" alt=""/>
                                        </div>
                                        <div className="mt-info  p-t20 text-white">
                                            <h4 className="mt-tilte m-b10 m-t0">Lait,<wbr/>Boissons lactés,<wbr/>Fromage,<wbr/>Yaourt...</h4>
                                        </div>
                                        {token ? (
                        <a 
                          onClick={() =>
                            handleCategoryClick('/ProductsAdmin', {
                              categoryId: [1,2,3],
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                        </a>
                      ) : (
                        <a 
                          onClick={() =>
                            handleCategoryClick('/Products', {
                              categoryId: [1,2,3],
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          </a>
                      )}                                                     
                                    </div>
                                </div>
                                
                                <div className="masonry-item  cat-2 col-md-3 col-sm-6 m-b30">
                                    <div className="mt-box   image-hover-block">
                                        <div className="mt-thum-bx">
                                            <img src="assets/images/projects/portrait/444.jpg" alt=""/>
                                        </div>
                                        <div className="mt-info  p-t20 text-white">
                                            <h4 className="mt-tilte m-b10 m-t0">Distillation,<wbr/>Extraction à froid...</h4>
                                        </div>
                                        {token ? (
                        <a 
                          onClick={() =>
                            handleCategoryClick('/ProductsAdmin', {
                              categoryId: [4,5,6,7],
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                        </a>
                      ) : (
                        <a 
                          onClick={() =>
                            handleCategoryClick('/Products', {
                              categoryId: [4,5,6,7],
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          </a>
                      )}                                                  
                                    </div>
                                </div>
                                
                                <div className="masonry-item  cat-2 col-md-3 col-sm-6 m-b30">
                                    <div className="mt-box   image-hover-block">
                                        <div className="mt-thum-bx">
                                            <img src="assets/images/projects/portrait/555.jpg" alt=""/>
                                        </div>
                                        <div className="mt-info  p-t20 text-white">
                                            <h4 className="mt-tilte m-b10 m-t0">Broyage,<wbr/>Tamisage,<wbr/>Torréfaction,<wbr/>Raffinage...</h4>
                                        </div>
                                        {token ? (
                        <a 
                          onClick={() =>
                            handleCategoryClick('/ProductsAdmin', {
                              categoryId: [12,13,14,15,16,17],
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                        </a>
                      ) : (
                        <a 
                          onClick={() =>
                            handleCategoryClick('/Products', {
                              categoryId: [12,13,14,15,16,17],
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          </a>
                      )}                                                 
                                    </div>
                                </div>
                                
                                <div className="masonry-item  cat-2 col-md-3 col-sm-6 m-b30">
                                    <div className="mt-box   image-hover-block">
                                        <div className="mt-thum-bx">
                                            <img src="assets/images/projects/portrait/333.jpg" alt=""/>
                                        </div>
                                        <div className="mt-info  p-t20 text-white">
                                            <h4 className="mt-tilte m-b10 m-t0">Séchage,<wbr/>Broyage,<wbr/>Lavage,<wbr/>Emballage...</h4>
                                        </div>
                                        {token ? (
                        <a 
                          onClick={() =>
                            handleCategoryClick('/ProductsAdmin', {
                              categoryId: [8,9,10,11],
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                        </a>
                      ) : (
                        <a 
                          onClick={() =>
                            handleCategoryClick('/Products', {
                              categoryId: [8,9,10,11],
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          </a>
                      )}                                                 
                                    </div>
                                </div>
                                
                                <div className="masonry-item  cat-2 col-md-3 col-sm-6 m-b30">
                                    <div className="mt-box   image-hover-block">
                                        <div className="mt-thum-bx">
                                            <img src="assets/images/projects/portrait/666.jpg" alt=""/>
                                        </div>
                                        <div className="mt-info  p-t20 text-white">
                                            <h4 className="mt-tilte m-b10 m-t0">Emulsification,<wbr/>Homogénéisation,<wbr/>Malaxage...</h4>
                                        </div>
                                        {token ? (
                        <a 
                          onClick={() =>
                            handleCategoryClick('/ProductsAdmin', {
                              categoryId: [18,19,20],
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                        </a>
                      ) : (
                        <a 
                          onClick={() =>
                            handleCategoryClick('/Products', {
                              categoryId: [18,19,20],
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          </a>
                      )}                                                    
                                    </div>
                                </div>
                                
                                <div className="masonry-item  cat-2 col-md-3 col-sm-6 m-b30">
                                    <div className="mt-box   image-hover-block">
                                        <div className="mt-thum-bx">
                                            <img src="assets/images/projects/portrait/222.jpg" alt=""/>
                                        </div>
                                        <div className="mt-info  p-t20 text-white">
                                            <h4 className="mt-tilte m-b10 m-t0">Cuiseurs,<wbr/>Broyeurs,<wbr/>Ustensiles,<wbr/>Agencement...</h4>
                                        </div>
                                        {token ? (
                        <a 
                          onClick={() =>
                            handleCategoryClick('/ProductsAdmin', {
                              categoryId: [21,22,23,24,25,26],
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                        </a>
                      ) : (
                        <a 
                          onClick={() =>
                            handleCategoryClick('/Products', {
                              categoryId: [21,22,23,24,25,26],
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          </a>
                      )}                                                    
                                    </div>
                                </div>
                                
                                <div className="masonry-item  cat-1 col-md-3 col-sm-6 m-b30">
                                    <div className="mt-box   image-hover-block">
                                        <div className="mt-thum-bx">
                                            <img src="assets/images/projects/portrait/1.jpg" alt=""/>
                                        </div>
                                        <div className="mt-info  p-t20 text-white">
                                            <h4 className="mt-tilte m-b10 m-t0">Autres Sécteurs</h4>
                                        </div>
                                        {token ? (
                        <a 
                          onClick={() =>
                            handleCategoryClick('/ProductsAdmin', {
                              categoryId: [28],
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                        </a>
                      ) : (
                        <a 
                          onClick={() =>
                            handleCategoryClick('/Products', {
                              categoryId: [28],
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          </a>
                      )}                                  
                                    </div>
                                </div>
                                <div className="masonry-item  cat-1 col-md-3 col-sm-6 m-b30">
                                    <div className="mt-box   image-hover-block">
                                        <div className="mt-thum-bx">
                                            <img src="assets/images/projects/portrait/2.jpg" alt=""/>
                                        </div>
                                        <div className="mt-info  p-t20 text-white">
                                            <h4 className="mt-tilte m-b10 m-t0">Projets clés en main</h4>
                                        </div>
                                        <a href="/Projects">     </a>                
                                    </div>
                                </div>  </div>

						</div>
                    
                </div>               
            </div>   
  )}
  export default Categories;
