import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';
import Loading from './Loading';

function About() {
  const [numYears, setNumYears] = useState(0);
  const [isVisible, setIsVisible] = useState([false, false, false, false]); 
  const countUpRefs = useRef([useRef(), useRef(), useRef(), useRef()]);
  const [loading, setLoading] = useState(true);

  document.title = "A propos de MAFI";

  useEffect(() => {
    const handleScroll = () => {
      countUpRefs.current.forEach((ref, index) => {
        if (ref.current) {
          const top = ref.current.getBoundingClientRect().top;
          const isVisible = top < window.innerHeight - 100; 
          setIsVisible((prev) => {
            if (prev[index] !== isVisible) {
              const newState = [...prev];
              newState[index] = isVisible;
              return newState;
            }
            return prev;
          });
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); 

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const year = formattedDate.split("/")[2];
    const numYearsSince = year - 2000;
    setNumYears(numYearsSince);
    setTimeout(() => {
      setLoading(false);
    }, 1000); 

  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="section-full" style={{ backgroundImage: "url(assets/images/background/bg-map.png)", marginTop: '20px' }}>
          <div className="services-half-section-top">
            <div className="container">
              <div className="section-head">
                <div className="mt-separator-outer separator-left text-wh">
                  <div className="mt-separator">
                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">A propos de</span> MAFI</h2>
                  </div>
                </div>
              </div>

              <div className="section-content">
                <div className="row">
                  <div className="col-lg-8 col-md-12 col-sm-12">
                    <div className="about-home-right about-right-v2 bg-primary text-black p-a30">
                      <h3 className="m-t0"><span className="font-weight-100">L'innovation au service de votre réussite industrielle, avec votre </span>fournisseur de solutions</h3>
                      <p><strong>
                        MAFI, votre partenaire en équipements agroalimentaires. Depuis l'année 2000, nous proposons une large gamme de matériel répondant aux normes internationales de qualité. Basés en Tunisie, nous fournissons des produits personnalisés et garantis, accompagnés d'un support technique compétent. Notre engagement est de répondre aux besoins spécifiques de nos clients dans le domaine agroalimentaire,cosmétique et parapharmaceutique en favorisant la productivité et en offrant un service client de qualité. MAFI, votre choix pour des solutions personnalisées, fiables et performantes.</strong></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="services-half-section-bottom p-t80 p-b50  bg-secondry bg-cover bg-center bg-no-repeat" style={{ backgroundImage: "url(assets/images/background/bg-13.jpg)" }}>
            <div className="container">
              <div className="section-content">
                <div className="row">
                  <div className="col-md-3 col-sm-6">
                    <div ref={countUpRefs.current[0]} className={`text-primary mt-icon-box-wraper left m-b30 ${isVisible[0] ? 'visible' : ''}`}>
                      <span className="icon-md p-t10">
                        <i className="fa fa-gears"></i>
                      </span>
                      <div className="icon-content text-white">
                        <div className="counter font-50 font-weight-800 m-b5">
                          {isVisible[0] && <CountUp start={0} end={500} duration={4} formattingFn={(value) => value.toFixed(0)} />}
                        </div>
                        <span className="font-16">Projets réalisés </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6">
                    <div ref={countUpRefs.current[1]} className={`text-primary mt-icon-box-wraper left m-b30 ${isVisible[1] ? 'visible' : ''}`}>
                      <span className="icon-md p-t10">
                        <i className="fa fa-thin fa-users"></i>
                      </span>
                      <div className="icon-content text-white">
                        <div className="counter font-50 font-weight-800 m-b5">
                          {isVisible[1] && <CountUp start={0} end={250} duration={4} formattingFn={(value) => value.toFixed(0)} />}
                        </div>
                        <span className="font-16">Clients satisfaits</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6">
                    <div ref={countUpRefs.current[2]} className={`text-primary mt-icon-box-wraper left m-b30 ${isVisible[2] ? 'visible' : ''}`}>
                      <span className="icon-md p-t10">
                        <i className="fa fa-handshake-o"></i>
                      </span>
                      <div className="icon-content text-white">
                        <div className="counter font-50 font-weight-800 m-b5">
                          {isVisible[2] && <CountUp start={0} end={100} duration={4} formattingFn={(value) => value.toFixed(0)} />}
                        </div>
                        <span className="font-16">Partenariats</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6">
                    <div ref={countUpRefs.current[3]} className={`text-primary mt-icon-box-wraper left m-b0 ${isVisible[3] ? 'visible' : ''}`}>
                      <span className="icon-md p-t10">
                        <i className="fa fa-calendar"></i>
                      </span>
                      <div className="icon-content text-white">
                        <div className="counter font-50 font-weight-800 m-b5">
                          {isVisible[3] && <CountUp start={0} end={numYears} duration={4} />}
                        </div>
                        <span className="font-16">ans d'expérience</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

 

    <div className="section-full p-t80 p-b50 square_shape1 bg-parallax bg-cover" data-stellar-background-ratio="0.5" style={{ backgroundColor: "#222021" }}>
  <div className="container">

    <div className="section-head text-center text-white">
      <div className="mt-separator-outer separator-center">
        <div className="mt-separator">
          <h2 className="text-white text-uppercase sep-line-one"><span className="font-weight-300 text-primary">Notre </span> Equipe</h2>
        </div>
      </div>
      <h2>Découvrir nos experts</h2>
    </div>

    <div className="section-content">
      <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
        
        <div className="col-md-3 col-sm-6 m-b30" style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="our-team-2 bg-white">
            <div className="profile-image scale-in-center">
              <img src="assets/images/our-team5/samir.png" alt="" />
            </div>
            <div className="figcaption text-black">
              <h4>Samir Layouni</h4>
              <h5>CEO <br />Founder</h5>
              <div className="icons">
                <a href="https://www.linkedin.com/in/layouni/"><i className="fa fa-linkedin"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 col-sm-6 m-b30" style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="our-team-2 bg-white">
            <div className="profile-image scale-in-center">
              <img src="assets/images/our-team5/marouen.png" alt="" />
            </div>
            <div className="figcaption text-black">
              <h4>Marouen Nasri</h4>
              <h5>Responsable <br />technico-commercial</h5>
              <div className="icons">
                <a href="https://www.linkedin.com/in/marwen-nasri-a031851a7/"><i className="fa fa-linkedin"></i></a>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
</div>



          <button className="scroltop" onClick={scrollToTop}><span className="fa fa-angle-up  relative" id="btn-vibrate"></span></button>
        </div>
      )}
    </div>
  );
}

export default About;
