import React, { useRef,useState,useEffect } from 'react';
import axios from 'axios';
import Loading from './Loading';

function Contact() {
  const [loading, setLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const formRef = useRef(null);
  document.title = "Contactez nous";


  useEffect(() => {
    setTimeout(() => {
        setLoading(false);
      }, 1000);
  });

  const sendEmail = async (e) => {
    e.preventDefault();
    
    try {
      const formData = new FormData(formRef.current);
      
      setLoading(true);  
  
      const response = await axios.post(`${baseUrl}/send_contact_email`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
  
      if (response.data && response.data.message === 'Email sent successfully') {
        formRef.current.reset();
      } else {
        console.error('Error sending email: Unexpected response from server');
      }
    } catch (error) {
      console.error('Error sending email:', error);
    } finally {
      setLoading(false); 
    }
  };
  
  console.log(loading)
  
  return (
    <div>
    {loading ? (
      <Loading />
    ) : (
    <div className="page-content">
      <div className="mt-bnr-inr overlay-wraper bg-parallax bg-top-center" data-stellar-background-ratio="0.5" style={{backgroundImage: "url(assets/images/banner/37.jpg)", backgroundPosition:"center"}}>
        <div className="overlay-main bg-black opacity-07"></div>
        <div className="container">
          <div className="mt-bnr-inr-entry">
            <div className="banner-title-outer">
              <div className="banner-title-name">
                <h1 className="m-b0">Besoin d'informations?</h1>
              </div>
            </div>
            <div>
              <ul className="mt-breadcrumb breadcrumb-style-2">
                <li><a href="/">Acceuil</a></li>
                <li>Produits</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section-full p-t80 p-b50 square_shape2 mobile-page-padding">
        <div className="section-full p-tb80 inner-page-padding">
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-md-8 col-sm-6">
                <form className="contact-form cons-contact-form" onSubmit={sendEmail} ref={formRef}>
                    <div className="contact-one m-b30">
                      <div className="section-head">
                        <div className="mt-separator-outer separator-left">
                          <div className="mt-separator">
                            <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Contactez</span> nous</h2>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <input name="user_name" type="text" required className="form-control" placeholder="Nom et Prénom" />
                      </div>
                      <div className="form-group">
                        <input name="user_number" type="number" required className="form-control" placeholder="Télephone" />
                      </div>
                      <div className="form-group">
                        <input name="user_email" type="email" required className="form-control" placeholder="Email" />
                      </div>
                      <div className="form-group">
                        <textarea name="message" rows="8" className="form-control" required placeholder="Message" />
                      </div>
                      <div className="text-right">
                        <button type="submit" className="site-button btn-effect">Envoyer</button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="contact-info m-b30">
                    <div className="section-head">
                      <div className="mt-separator-outer separator-left">
                        <div className="mt-separator">
                          <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Nos</span> coordonnées</h2>
                        </div>
                      </div>
                    </div>
                    <div className="p-a20 text-white" style={{backgroundColor:'#222'}}>
                      <div className="mt-icon-box-wraper left p-b40">
                        <div className="icon-xs"><i className="fa fa-phone"></i></div>
                        <div className="icon-content">
                          <h5 className= "m-t0 font-weight-500">Télephone</h5>
                          <p><a href="tel:+21671516321" >(+216) 71 516 321</a></p>
                        </div>
                      </div>
                      <div className="mt-icon-box-wraper left p-b40">
                        <div className="icon-xs"><i className="fa fa-fax"></i></div>
                        <div className="icon-content">
                          <h5 className= "m-t0 font-weight-500">Fax</h5>
                          <p><a href="tel:+21671516321" >(+216) 71 516 321</a></p>
                        </div>
                      </div>
                      <div className="mt-icon-box-wraper left p-b40">
                        <div className="icon-xs"><i className="fa fa-envelope"></i></div>
                        <div className="icon-content">
                          <h5 className="m-t0 font-weight-500">Adresse email</h5>
                          <p><a href="mailto:mafi@orange.tn">mafi@orange.tn</a></p>
                        </div>
                      </div>
                      <div className="mt-icon-box-wraper left">
                        <div className="icon-xs"><i className="fa fa-map-marker"></i></div>
                        <div className="icon-content">
                          <h5 className="m-t0 font-weight-500">Adresse</h5>
                          <p>Rue Taieb Mhiri, Khaznadar, Tunis, Tunisia</p>
                        </div>
                      </div>
                      <div className="full-social-bg">
                        <ul  style={{marginTop:'10px'}}>
                          <li><a href="https://www.facebook.com/mafitunisia" className="facebook" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a></li>
                          <li><a href="https://www.linkedin.com/company/maison-de-fournitures-industrielles-mafi/mycompany/"  className="linkedin" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a></li>
                          <li><a href="https://www.youtube.com/channel/UCrC3yD6IewWFZbXKNW2YiUg" className="youtube" target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="gmap-outline">
              <div className="google-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3194.5978823947034!2d10.11776317520087!3d36.804190572245986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd324bd13ea205%3A0x1d5d98e81371c5d!2sMAFI!5e0!3m2!1sfr!2stn!4v1688588906978!5m2!1sfr!2stn"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  style={{
                    width: '100%',
                    height: '100%',
                    border: '0',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.8)'
                  }}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )}
    </div>
  );
}

export default Contact;
