import React, { useState, useEffect } from "react";
import Loading from './Loading';

function LellaKmar() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
        <div className="section-full p-tb80 inner-page-padding">
        <div className="container">
            <div className="project-detail-outer">
                <div className="m-b0">
                    <div className="row">
                        <div className="col-md-4 col-sm-4">
                            <div className="project-detail-pic m-b30">
                                <div className="mt-media">
                                    <img src="assets/images/projects/portrait/proj3.jpg" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="project-detail-pic m-b30">
                                <div className="mt-media">
                                    <img src="assets/images/projects/portrait/proj1.jpg" alt=""/>
                                </div>
                            </div>                        	
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="project-detail-pic m-b30">
                                <div className="mt-media">
                                    <img src="assets/images/projects/portrait/proj2.jpg" alt=""/>
                                </div>
                            </div>                        	
                        </div>                                
                    </div>
                </div> 
                
                <div className="m-b30">
                    <div className="row2">
                        <div className="col-md-4">
                            <div className="product-block">
                                <div className="row2">
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Date</h4>
                                    <p>Février, 2022</p>
                                  </div>
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Client</h4>
                                    <p>Lella Kmar El Baya</p>
                                   </div>
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Type de Projet</h4>
                                    <p>Valorisation de la Production Locale Agroalimentaire</p>
                                  </div>
                                  <div className="col-md-12 m-b10">
                                    <h4 className="text-uppercase font-weight-600 m-b10">Localisation</h4>
                                    <p>El battan</p>
                                  </div>                                          
                              </div>
                            </div>  
                        </div>
                        <div className="col-md-8">
    <div className="mt-box">
        <div className="mt-thum-bx2 mt-img-overlay1 mt-img-effect yt-thum-box">
            <iframe 
                src="https://www.facebook.com/plugins/video.php?href=https://www.facebook.com/maghrebvoices/videos/2580421102099974"
                width="100%" 
                height="auto" 
                title="Facebook Video"
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none'}}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
            </iframe>
        </div>
    </div>                   
</div>

                    </div>
                </div>                         
                
                                   

                <div className="project-detail-containt">
                    <div className="bg-white text-black">
                        <h3 className="font-weight-600">Valorisation de la Production Locale Agroalimentaire</h3>
                        <p> Le projet "Valorisation de la Production Locale Agroalimentaire" soutient la coopérative agricole mutuelle Lella Kmar El Baya, qui se distingue par son engagement à préserver et promouvoir les plantes anciennes tunisiennes. En multipliant, cultivant et transformant des variétés traditionnelles comme le blé Mahmoudi, la coopérative crée des produits alimentaires naturels, sains et artisanaux. </p>
                        <p> Ce projet vise à renforcer l’impact économique et social de cette coopérative en augmentant la visibilité et la consommation des produits locaux, tout en offrant de nouvelles opportunités d'emploi et en soutenant le développement durable de l'agriculture tunisienne. </p>
                        
                        
                        <div className="m-b0">
                            <div className="mt-divider divider-1px  bg-black"><i className="icon-dot c-square"></i></div>
                        </div>                                
                    </div>
                </div>
              
            </div>
        </div>
    </div>
      
</div>
)}
</div>)
}

export default LellaKmar;
