import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import "./NavBar.css";

function NavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const token = Cookies.get("token");
  const [click, setClick] = useState(false);
  const [searchKeywords, setSearchKeywords] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null); 
  const searchRef = useRef(null);

  const handleDivClick = () => {
    setIsClicked(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClick = () => setClick(!click);

  const handleSearchChange = (event) => {
    setSearchKeywords(event.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchKeywords.trim() !== "") {
      navigate("/ProductsResult", { state: { keywords: searchKeywords } });
      setSearchKeywords("");
      handleClick();
      closeNavBar();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchSubmit(e);
    }
  };

  useEffect(() => {
    
    if (!click) {
      setOpenSubmenuIndex(null); 
    }
  }, [click]);

  const handleSubMenuClick = (path, state) => {
    navigate(path, { state });
    setClick(!click);
  };

  const toggleSubMenu = (index) => {
    setOpenSubmenuIndex(prevIndex => (prevIndex === index ? null : index));
  };

  const closeNavBar = () => {
    setClick(false);
  };



  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
  <NavLink
    exact="true"
    to="/"
    className="nav-links"
    onClick={closeNavBar}
  >
    Acceuil
  </NavLink>
</li>

            <li className="nav-item">
              {token ? (
                <a
                  className={`nav-links ${
                    location.pathname.startsWith('/ProductsAdmin') ? 'active' : ''
                  }`}
                  style={{ cursor: 'pointer' }}
                  
                >
                  Produits
                </a>
              ) : (
                <a
                  className={`nav-links ${
                    location.pathname === '/Products' ? 'active' : ''
                  }`}
                  style={{ cursor: 'pointer' }}
                
                >
                  Produits
                </a>
              )}
              <ul className="sub-menu">
                <li className="has-child" onClick={() => toggleSubMenu(0)}>
                  <a  >Lait & dérivés</a> 
                  <i
                    className={`fa ${openSubmenuIndex === 0 ? "fa-chevron-down" : "fa-chevron-right"}`}
                    style={{ marginLeft: "10px" }}
                  ></i>
                  {openSubmenuIndex === 0 && (
                    <ul className="sub-menu has-child">
                      <li>
                        {token ? (
                          <a
                            onClick={() =>
                              handleSubMenuClick('/ProductsAdmin', {
                                categoryId: 1,
                              })
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            Lait & boissons lactés
                          </a>
                        ) : (
                          <a
                            onClick={() =>
                              handleSubMenuClick('/Products', {
                                categoryId: 1,
                              })
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            Lait & boissons lactés
                          </a>
                        )}
                      </li>
                      <li>
                        {token ? (
                          <a
                            onClick={() =>
                              handleSubMenuClick('/ProductsAdmin', {
                                categoryId: 2,
                              })
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            Yaourt
                          </a>
                        ) : (
                          <a
                            onClick={() =>
                              handleSubMenuClick('/Products', {
                                categoryId: 2,
                              })
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            Yaourt
                          </a>
                        )}
                      </li>
                      <li>
                        {token ? (
                          <a
                            onClick={() =>
                              handleSubMenuClick('/ProductsAdmin', {
                                categoryId: 3,
                              })
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            Fromage & dérivés
                          </a>
                        ) : (
                          <a
                            onClick={() =>
                              handleSubMenuClick('/Products', {
                                categoryId: 3,
                              })
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            Fromage & dérivés
                          </a>
                        )}
                      </li>
                    </ul>
                  )}
                
                </li>
                <li className="has-child" onClick={() => toggleSubMenu(1)}>
                  <a>Huiles & huiles essentielles</a>
                  <i
                    className={`fa ${openSubmenuIndex === 1 ? "fa-chevron-down" : "fa-chevron-right"}`}
                    style={{ marginLeft: "10px" }}
                  ></i>
                  {openSubmenuIndex === 1 && (
                  <ul className="sub-menu has-child">
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 4,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Distillation
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 4,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Distillation
                          </a>
                      )}
                    </li>
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 5,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Extraction à froid
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 5,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Extraction à froid
                        </a>
                      )}
                    </li>
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 6,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Filtration
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 6,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Filtration
                        </a>
                      )}
                    </li>
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 7,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Emballage
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 7,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Emballage
                        </a>
                      )}
                    </li>
                  </ul>
                  )}
                </li>
                <li className="has-child" onClick={() => toggleSubMenu(2)}>
                  <a>Plantes aromatiques & médicinales</a>
                  <i
                    className={`fa ${openSubmenuIndex === 2 ? "fa-chevron-down" : "fa-chevron-right"}`}
                    style={{ marginLeft: "10px" }}
                  ></i>
                  {openSubmenuIndex === 2 && (
                  <ul className="sub-menu has-child">
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 8,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Séchage
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 8,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Séchage
                          </a>
                      )}
                    </li>
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 9,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Broyage
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 9,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Broyage
                        </a>
                      )}
                    </li>
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 10,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Lavage
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 10,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Lavage
                        </a>
                      )}
                    </li>
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 11,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Emballage
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 11,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Emballage
                        </a>
                      )}
                    </li>
                  </ul>
                  )}
                </li>
                <li className="has-child" onClick={() => toggleSubMenu(3)}>
                  <a>Valorisation des grains</a>
                  <i
                    className={`fa ${openSubmenuIndex === 3 ? "fa-chevron-down" : "fa-chevron-right"}`}
                    style={{ marginLeft: "10px" }}
                  ></i>
                  {openSubmenuIndex === 3 && (
                  <ul className="sub-menu has-child">
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 12,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Broyage
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 12,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Broyage
                          </a>
                      )}
                    </li>
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 13,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Raffinage
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 13,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Raffinage
                        </a>
                      )}
                    </li>
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 14,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Tamisage
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 14,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Tamisage
                        </a>
                      )}
                    </li>
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 15,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Torréfaction
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 15,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Torréfaction
                        </a>
                      )}
                    </li>
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 16,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Emballage
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 16,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Emballage
                        </a>
                      )}
                    </li>
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 17,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Mélangeurs & homogénéisateurs
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 17,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Mélangeurs & homogénéisateurs
                        </a>
                      )}
                    </li>
                  </ul>
                  )}
                </li>
                <li className="has-child" onClick={() => toggleSubMenu(4)}>
                  <a>Cosmétique pharmaceutique & parapharmaceutique</a>
                  <i
                    className={`fa ${openSubmenuIndex === 4 ? "fa-chevron-down" : "fa-chevron-right"}`}
                    style={{ marginLeft: "10px" }}
                  ></i>
                  {openSubmenuIndex === 4 && (
                  <ul className="sub-menu has-child">
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 18,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Emulsification
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 18,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Emulsification
                          </a>
                      )}
                    </li>
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 19,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Homogénéisation
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 19,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Homogénéisation
                        </a>
                      )}
                    </li>
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 20,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Malaxage
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 20,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Malaxage
                        </a>
                      )}
                    </li>
                  </ul>
                  )}
                </li>
                <li className="has-child" onClick={() => toggleSubMenu(5)}>
                  <a>Confiserie & cuisine</a>
                  <i
                    className={`fa ${openSubmenuIndex === 5 ? "fa-chevron-down" : "fa-chevron-right"}`}
                    style={{ marginLeft: "10px" }}
                  ></i>
                  {openSubmenuIndex === 5 && (
                  <ul className="sub-menu has-child">
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 21,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Cuiseurs
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 21,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Cuiseurs
                          </a>
                      )}
                    </li>
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 22,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Broyeurs
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 22,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Broyeurs
                        </a>
                      )}
                    </li>
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 23,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Raffineuses
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 23,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Raffineuses
                        </a>
                      )}
                    </li>
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 24,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Séparateurs
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 24,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Séparateurs
                        </a>
                      )}
                    </li>
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 25,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Ustensiles
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 25,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Ustensiles
                        </a>
                      )}
                    </li>
                    <li>
                      {token ? (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/ProductsAdmin', {
                              categoryId: 26,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Agencement
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            handleSubMenuClick('/Products', {
                              categoryId: 26,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          Agencement
                        </a>
                      )}
                    </li>
                  </ul>
                  )}
                </li>



                <li>
                  {token ? (
            <a
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              handleSubMenuClick('/ProductsAdmin', { categoryId: 27 });
            }}
          >
            Hygiéne et sécurité
          </a>
          
                  ) : (
                    <a
                      onClick={() =>
                        handleSubMenuClick('/Products', {
                          categoryId: 27,
                        })
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      Hygiéne et sécurité
                    </a>
                  )}
                </li>







                <li>
                  {token ? (
                    <a
                      onClick={() =>
                        handleSubMenuClick('/ProductsAdmin', {
                          categoryId: 28,
                        })
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      Autres sécteurs
                    </a>
                  ) : (
                    <a
                      onClick={() =>
                        handleSubMenuClick('/Products', {
                          categoryId: 28,
                        })
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      Autres sécteurs
                    </a>
                  )}
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <NavLink
                exact="true"
                to="/About"
                className="nav-links"
                onClick={closeNavBar}
              >
                A propos
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact="true"
                to="/Contact"
                className="nav-links"
                onClick={closeNavBar}
              >
                Contact
              </NavLink>
            </li>
            {token && (
              <>
                <li className="nav-item">
                  <a
                    className="nav-links"
                    onClick={() => {
                      navigate('/AddProduct');
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Ajouter un produit
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-links"
                    onClick={() => {
                      navigate('/Devis');
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Devis
                  </a>
                </li>
              </>
            )}
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
          </div>
          <div className="nav-divider"></div>
          <div
            ref={searchRef}
            className={`header-search ${isClicked ? 'clicked' : ''}`}
            onClick={handleDivClick}
          >
            <input
              type="text"
              placeholder="Rechercher"
              value={searchKeywords}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              className="header-search-input"
            />
            <i className="fa fa-search search-icon" onClick={handleSearchSubmit}></i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
