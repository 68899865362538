import React, { useState} from "react"; 
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import NavBar from "./NavBar";


export default function Header(){
const navigate = useNavigate();
const [searchKeywords, setSearchKeywords] = useState('');
const token = Cookies.get('token'); 

const [cart, setCart] = useState(
    JSON.parse(sessionStorage.getItem("cart")) || []
  );
  const getTotalCartCount = () => {
    return cart.reduce((total, item) => total + parseInt(item.quantity), 0);
  };
  const [fav, setFav] = useState(
    JSON.parse(sessionStorage.getItem("fav")) || []
  );
  const getTotalFavCount = () => {
    return fav.length
  };


const handleSearchChange = (event) => {
    setSearchKeywords(event.target.value);
};

const handleSearchSubmit = (e) => {
    e.preventDefault(); 
    if (searchKeywords.trim() !== '') {
        navigate('/ProductsResult', { state: { keywords: searchKeywords } });
        setSearchKeywords(''); 
    }
};
const handleRemoveCartItem = (index) => {
    const updatedCart = cart.filter((item, i) => i !== index);
    setCart(updatedCart);
    sessionStorage.setItem("cart", JSON.stringify(updatedCart));
  };
  const handleRemoveFaveItem = (index) => {
    const updatedFav = fav.filter((item, i) => i !== index);
    setFav(updatedFav);
    sessionStorage.setItem("fav", JSON.stringify(updatedFav));
    navigate('/');
  };
const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
        handleSearchSubmit(e); 
    }
};

const handleLogout = async () => {
        Cookies.remove('token');
        navigate('/');
}

return(<header className="site-header header-style-2">
            <div className="header-middle bg-white">
                <div className="container">
                    <div className="logo-header">
                        <a href="/">
                            <img src="assets/images/logo-mafi.png" width="216" height="37" alt="" />
                        </a>
                    </div>
                    <div className="header-info">
                        <ul>
                        	<li>
                                <div className="icon-sm">
                                    <a href="https://maps.app.goo.gl/u9bVc6ds4RPSg87A7" className="icon-cell  text-primary"><i className="fa fa-map-marker"></i></a>
                                </div>
                                <div className="icon-content">
                                  <a href="https://maps.app.goo.gl/u9bVc6ds4RPSg87A7">
                                    <strong>Tunis, Tunisie</strong>
                                    <span>Khaznadar, Le Bardo</span>
                                    </a>
                                </div>
                            </li>
                            <li>
                           
                                <div className="icon-sm" style={{marginTop:"18px"}}>
                                <a href="tel:+21671516321"> <span className="icon-cell  text-primary"  ><i className="fa fa-phone"></i></span></a>
                                </div>
                                <div className="icon-content">
                                <strong><a href="tel:+21671516321">+216 71 516 321</a></strong>
                                    <span>Lundi - Vendredi: 8h à 17h</span>
                                    <br/>
                                    <span>Samedi: 8h à 12h</span>

                                </div>
                            
                            </li>
                            <li className="btn-col-last">
                            {token ? (
                    <a onClick={() => handleLogout()} className="header-cart">
                    <i className="fa fa-sign-out"></i>
                    </a>

                ) : (

                    <div className="dropdown dropdown-hover">
                    <a href="#" className="header-cart" data-toggle="dropdown" style={{ color: "#000" }}>
                      <i className="fa fa-file-text"></i>
                      <span className="total" style={{ marginLeft: "-10px" }}>{getTotalCartCount()}</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-cart p-0">
                      <div className="cart-header">
                        <h4 className="cart-title">Articles du devis ({getTotalCartCount()})</h4>
                      </div>
                      {cart.length ?(
                      <div className="cart-body">
                        <ul className="cart-item">
                          {cart.map((item, index) => (
                            <li key={index}>
                             <div className="cart-item-image"  onClick={() =>
            navigate('/ProductDetails', { state: { productId: item.id } })
          }><img src={`data:image/png;base64,${item.image}`} alt="" /></div>
                             <div className="cart-item-info">
                             <h4>{item.name}</h4>   
                             <p className="price">Quantité: {item.quantity}</p>
                                                              </div>
                           
                <div className="cart-item-close">
                          <a onClick={() => handleRemoveCartItem(index)} data-toggle="tooltip" data-title="Supprimer">&times;</a>
                                                              </div>
              
              
              
              
                            </li>
                          ))}
                        </ul>
                      </div>):(<p style={{marginLeft:'20px'}}>Pas de produits</p>)}
                      <div className="cart-footer">
                        <div className="row row-space-10">
                          <div className="col-xs-6">
                            <a href="/CheckoutCart" className="btn btn-default btn-block">Voir la demande </a>
                          </div>
                          <div className="col-xs-6">
                            <a href="/CheckoutInfo" className="btn btn-inverse btn-block">Valider</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>                )}                            </li>
                 {!token && <li>
                  
                  <div className="dropdown dropdown-hover">
                    <a href="#" className="header-cart" data-toggle="dropdown" style={{ color: "#000" }}>
                      <i className="fa fa-heart"></i>
                      <span className="total" style={{ marginLeft: "-10px" }}>{getTotalFavCount()}</span>
                    </a>
                    
                    <div className="dropdown-menu dropdown-menu-cart p-0">
                      <div className="cart-header">
                        <h4 className="cart-title">Articles favoris ({getTotalFavCount()})</h4>
                      </div>
                      {fav.length ?(
                      <div className="cart-body">
                        <ul className="cart-item">
                          {fav.map((item, index) => (
                            <li key={index}>
                             <div className="cart-item-image"  onClick={() =>
            navigate('/ProductDetails', { state: { productId: item.id } })
          }><img src={`data:image/png;base64,${item.image}`} alt="" /></div>
                             <div className="cart-item-info">
                             <h4>{item.name}</h4>   
                             <div className="price">{item.price !== null ? (
              <p>{item.price.toFixed(3)} DT</p>
            ) : (
              <p>Prix sur devis</p>
            )}</div>
                                                              </div>
                           
                <div className="cart-item-close">
                          <a onClick={() => handleRemoveFaveItem(index)} data-toggle="tooltip" data-title="Supprimer">&times;</a>
                                                              </div>
              
              
              
              
                            </li>
                          ))}
                        </ul>
                      </div>):(<p style={{marginLeft:'20px'}}>Pas de favoris</p>)}
                      <div className="cart-footer">
                        <div className="row row-space-10">
                          <div className="col-xs-6">
                            <a href="/FavProducts" className="btn btn-default btn-block">Voir favoris </a>
                          </div>
                          
                        </div>
                      </div>
                    </div>

                  </div> 
                  </li>}
                        </ul>
                    </div>
                </div>
            </div>
                                    <NavBar/>
            
        </header>);}
