import React ,{useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';



function CheckoutCart(){
    document.title = "Demande de devis";
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [cart, setCart] = useState(JSON.parse(sessionStorage.getItem("cart"))||[]);
      useEffect(() => {
      setLoading(false);
      });
      const handleRemoveCartItem = (index) => {
        const updatedCart = cart.filter((item, i) => i !== index);
        setCart(updatedCart);
        sessionStorage.setItem("cart", JSON.stringify(updatedCart));
        if (updatedCart.length === 0) { 
            navigate('/Products'); 
          }
          window.location.reload(); 

      };
      const handleQuantityChange = (index, change) => {
        const updatedCart = [...cart];
        updatedCart[index].quantity = Math.max(1, updatedCart[index].quantity + change); 
        setCart(updatedCart);
        sessionStorage.setItem('cart', JSON.stringify(updatedCart));
        window.location.reload(); 
      };
      
    return(
        <div>
        {loading ? (
          <Loading />
        ) : (
<div className="section-container" id="checkout-cart">

<div className="container">
  
    <div className="checkout">
        <form  name="form_checkout">
           
            <div className="checkout-header">
             
                <div className="row">
                   
                    <div className="col-md-3 col-sm-3">
                        <div className="step active">
                            <a href="/CheckoutCart">
                                <div className="number">1</div>
                                <div className="info">
                                    <div className="title">Details du devis</div>
                                </div>
                            </a>
                        </div>
                    </div>
                    
                    <div className="col-md-3 col-sm-3">
                        <div className="step">
                            <a href="/CheckoutInfo">
                                <div className="number">2</div>
                                <div className="info">
                                    <div className="title">Infoamtions client</div>
                                </div>
                            </a>
                        </div>
                    </div>
                   
                 
                    
                    <div className="col-md-3 col-sm-3">
                        <div className="step">
                            <a href="/CheckoutComplete">
                                <div className="number">3</div>
                                <div className="info">
                                    <div className="title">Demande envoyée</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="checkout-body">
                <div className="table-responsive">
                    <table className="table table-cart">
                        <thead>
                            <tr>
                                <th>Produit</th>
                                <th className="text-center">Quantité</th>
                                <th className="text-center">Supprimer</th>

                            </tr>
                        </thead>
                        <tbody>
                            
                            {cart.map((item, index) => (
                                <tr key={index}>
                                <td className="cart-product">
                                    <div className="product-img">
                                        <img src={`data:image/png;base64,${item.image}`} alt="" />
                                    </div>
                                    <div className="product-info">
                                        <div className="title">{item.name}</div>
                                    </div>
                                </td>
                                <td className="cart-qty text-center">
                                    <div className="cart-qty-input">
                                    <a
          
          className="qty-control left"
          onClick={(e) => handleQuantityChange(index, -1)}
        ><i className="fa fa-minus"></i></a>
 <input
          type="text"
          name="qty"
          value={item.quantity}
          className="form-control"
          id={`qty-${index}`}
          readOnly
        />                                        
 <a
          
          className="qty-control right"
          onClick={(e) => handleQuantityChange(index, 1)}
        >            <i className="fa fa-plus"></i></a>
                                    </div>
                                  
                                </td>
                                <td className="cart-total text-center">
                                    <div className="cart-item-close" style={{marginLeft:'30px',marginTop:'10px'}}>
                          <a onClick={() => handleRemoveCartItem(index)} >&times;</a>
                                                              </div>                                            </td>
                                  
                                </tr>
                                 ))}
                           
                      
                        </tbody>
                    </table>
                </div>
            </div>
          
            <div className="checkout-footer">
               
                <button onClick={() =>navigate('/Products', { state: { categoryId: null }} )}
                        className="btn btn-white btn-lg pull-left">Retour vers produits</button>
                <a href="CheckoutInfo" className="btn btn-inverse btn-lg p-l-30 p-r-30 m-l-10">Suivant</a>
            </div>
           
        </form>
    </div>
  
</div>

</div>
        )}
        </div>
    );
}
export default CheckoutCart;