import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import Loading from './Loading';

const baseUrl = process.env.REACT_APP_BASE_URL;

function CheckoutInfo() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [processingEmail, setProcessingEmail] = useState(false);
  const [cart, setCart] = useState(JSON.parse(sessionStorage.getItem("cart")) || []);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  document.title = "Demande de devis";

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessingEmail(true);
  
    try {
      const devisRequests = cart.map(product => ({
        product_id: product.id,
        client_name: name,
        quantity: product.quantity,
        client_email: email,
        client_phone: phone,
        description: description
      }));
  
      const addDevisResponse = await axios.post(`${baseUrl}/add_devis_request`, devisRequests);
  
      if (addDevisResponse.data.message === "Devis requests added successfully") {
        navigate("/CheckoutComplete");
  
        axios.post(`${baseUrl}/send_email`, {
          user_name: name,
          user_phone: phone,
          user_email: email,
          description: description,
          products: cart
        }).catch(error => console.error("Error sending email:", error));
  
        setName("");
        setDescription("");
        setPhone("");
        setEmail("");
        setCart([{ name: "", quantity: "", brand: "", reference: "" }]);
        sessionStorage.removeItem("cart");
        window.location.reload();
      } else {
        console.error("Error adding devis request:", addDevisResponse.data.message);
      }
    } catch (error) {
      console.error("Error processing request:", error);
    } finally {
      setProcessingEmail(false);
    }
  };
  

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="section-container" id="checkout-info">
          <div className="container">
            <div className="checkout">
              <form onSubmit={handleSubmit} className="form-horizontal">
                <div className="checkout-header">
                  <div className="row">
                    <div className="col-md-3 col-sm-3">
                      <div className="step">
                        <a href="/CheckoutCart">
                          <div className="number">1</div>
                          <div className="info">
                            <div className="title">Details du devis</div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <div className="step active">
                        <a href="/CheckoutInfo">
                          <div className="number">2</div>
                          <div className="info">
                            <div className="title">Informations client</div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <div className="step">
                        <a href="/CheckoutComplete">
                          <div className="number">3</div>
                          <div className="info">
                            <div className="title">Demande envoyée</div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="checkout-body">
                  <div className="form-group">
                    <label className="control-label col-md-4">Nom et Prénom</label>
                    <div className="col-md-4">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder=""
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-4">Téléphone</label>
                    <div className="col-md-4">
                      <input
                        type="tel"
                        className="form-control"
                        name="primary_phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder=""
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-4">Email</label>
                    <div className="col-md-4">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder=""
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-4">Description</label>
                    <div className="col-md-4">
                      <input
                        type="text"
                        className="form-control"
                        name="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder=""
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="checkout-footer">
                  <a href="/CheckoutCart" className="btn btn-white btn-lg pull-left">Précédent</a>
                  <button type="submit" className="btn btn-inverse btn-lg p-l-30 p-r-30 m-l-10">Confirmer</button>
                </div>
              </form>
              {processingEmail && (
                <div className="loading-overlay">
                  <div className="loading-indicator">
                    <ClipLoader color="#F5BF23" loading={processingEmail} size={50} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CheckoutInfo;
