import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Loading from './Loading';
import { Helmet } from 'react-helmet';

function Login() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  document.title = "Admin Login";

  useEffect(() => {
    document.title = 'Se connecter';
    setLoading(false);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${baseUrl}/login`, { username, password });
      const { access_token, id } = response.data;
      Cookies.set('token', access_token);
      sessionStorage.setItem('id', id);

      navigate('/Devis');
    } catch (error) {
      setError('Login ou mot de passe invalide(s)'); 
    }
  };



  return (
    <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="section-full p-t80 p-b50 square_shape2 mobile-page-padding">
          <div className="section-full p-tb80 inner-page-padding">
            <div className="container">
              <div className="section-content">
                <div className="row">
                  <div className="col-md-8 col-sm-6">
                    <form onSubmit={handleSubmit} className="contact-form cons-contact-form">
                      <div className="contact-one m-b30">
                        <div className="section-head">
                          <div className="mt-separator-outer separator-left">
                            <div className="mt-separator">
                              <h2 className="text-uppercase sep-line-one">
                                <span className="font-weight-300 text-primary">Connectez</span> vous
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            value={username}
                            className="form-control"
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Nom d'utilisateur"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            className="form-control form-control-lg"
                            autoComplete="off"
                            placeholder="Mot de passe"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          {error && <div className="error-message">{error}</div>} 
                        </div>
                        <div className="text-right">
                          <button type="submit" className="site-button btn-effect">Envoyer</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
}

export default Login;
