import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from "js-cookie";
import Loading from './Loading';

function ProductsResult() {
  const [loading, setLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = Cookies.get("token");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [keywords, setKeywords] = useState("");  
  const navigate = useNavigate();
  const location = useLocation();
  const { keywords: initialKeywords } = location.state || {};

  document.title = "Résultats de la recherche";

  useEffect(() => {
    if (initialKeywords) {
      setKeywords(initialKeywords); 
    }
  }, [initialKeywords]);

  useEffect(() => {
    const fetchProducts = async () => {
      if (!keywords) {
        setLoading(false);
        setFilteredProducts([]);
        return;
      }
      setLoading(true); 

      try {
        const response = await axios.post(`${baseUrl}/get_search_products`, {
          searchTerm: keywords,
        });
        setFilteredProducts(response.data);
        setLoading(false); 
      } catch (error) {
        console.error('Error retrieving products:', error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, [keywords]);  

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const productsPerPage = 12;

  const getCurrentPageProducts = () => {
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    return filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      const isActive = i === currentPage;
      pages.push(
        <li key={i} className={isActive ? 'active' : ''}>
          <a onClick={() => handlePageChange(i)}>
            {i}
          </a>
        </li>
      );
    }

    return (
      <div className="text-center">
        <ul className="pagination m-t-0">
          <li className={currentPage === 1 ? 'disabled' : ''}>
            <a onClick={() => handlePageChange(currentPage - 1)}>
              Précédent
            </a>
          </li>
          {pages}
          <li className={currentPage === totalPages ? 'disabled' : ''}>
            <a onClick={() => handlePageChange(currentPage + 1)}>
              Suivant
            </a>
          </li>
        </ul>
      </div>
    );
  };

  const renderProducts = () => {
    const productsToDisplay = getCurrentPageProducts();
    if (productsToDisplay.length === 0) {
      return <p style={{ textAlign: 'center', marginTop: '20px', fontSize: '20px' }}>Aucun résultat trouvé</p>;
    }

    return productsToDisplay.map((product) => (
      <div className="item item-thumbnail" key={product.id}>
        {token ? (
          <a
            onClick={() =>
              navigate('/ProductDetailsAdmin', { state: { productId: product.id } })
            }
            className="item-image"
          >
            {product.photo_data_list && product.photo_data_list.length > 0 && (
              <img
                src={`data:image/png;base64,${product.photo_data_list[0].data}`}
                alt={product.name}
              />
            )}
          </a>
        ) : (
          <a
            onClick={() =>
              navigate('/ProductDetails', { state: { productId: product.id } })
            }
            className="item-image"
          >
            {product.photo_data_list && product.photo_data_list.length > 0 && (
              <img
                src={`data:image/png;base64,${product.photo_data_list[0].data}`}
                alt={product.name}
              />
            )}
          </a>
        )}

        <div className="item-info">
          <p className="item-desc">{product.brand}</p>
          <h4 className="item-title" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {token ? (
              <a href={`/ProductDetailsAdmin/${product.id}`}>{product.name}</a>
            ) : (
              <a href={`/ProductDetails/${product.id}`}>{product.name}</a>
            )}
          </h4>
          <div className="item-price">
            {product.price !== null ? `${product.price.toFixed(3)} TND` : "Prix sur devis"}
          </div>
        </div>
      </div>
    ));
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    scrollToTop();
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <div id="search-results" className="section-container bg-silver">
            <div className="container">
              <div className="search-container">
                <div className="search-content" style={{ width: '100%' }}>
                  <div className="search-toolbar">
                    <div className="row">
                      <div className="col-md-6">
                        <h4>{filteredProducts.length} produit(s) trouvé(s) pour : "{keywords}"</h4>
                      </div>
                    </div>
                  </div>

                  <div className="search-item-container">
                    <div className="item-row">
                      {renderProducts()}
                    </div>
                  </div>

                  {renderPagination()}
                </div>
              </div>
            </div>
          </div>

          <button className="scroltop" onClick={scrollToTop}>
            <span className="fa fa-angle-up relative" id="btn-vibrate"></span>
          </button>
        </div>
      )}
    </div>
  );
}

export default ProductsResult;
