import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const PartnersCarousel2 = () => {
  const partners = [
    { logo: '11.png', url: 'https://www.banquezitouna.com/fr/groupe-banque-zitouna/zitouna-tamkeen' },
    { logo: '22.png', url: 'https://www.bts.com.tn/' },
    { logo: '33.png', url: 'https://www.endatamweel.tn/' },
    { logo: '44.png', url: 'https://www.tlf.com.tn/' },
    { logo: '55.png', url: 'https://www.ilo.org/fr' },

  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2,
    },
  };

  const partnerLogoStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
    filter: 'grayscale(100%)', 
    transition: 'filter 0.3s ease',
  };

  const partnerItemStyle = {
    padding: '10px', 
  };

  return (
    <Carousel responsive={responsive} autoPlay={true} infinite={true} showArrows={false}>
      {partners.map((partner, index) => (
        <div key={index} style={partnerItemStyle}>
          <a href={partner.url} target="_blank" rel="noopener noreferrer">
            <img
              src={`assets/images/${partner.logo}`}
              alt={`Partner ${index}`}
              style={partnerLogoStyle}
              onMouseOver={(e) => e.currentTarget.style.filter = 'grayscale(0%)'} 
              onMouseOut={(e) => e.currentTarget.style.filter = 'grayscale(100%)'} 
            />
          </a>
        </div>
      ))}
    </Carousel>
  );
};

export default PartnersCarousel2;
