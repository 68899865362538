import React from "react";
import { useNavigate } from 'react-router-dom';


function CheckoutComplete(){
    const navigate = useNavigate();
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    document.title = "Demande de devis";
    return(
        <div className="section-container" id="checkout-cart">
        <div className="container">
            <div className="checkout">
                <form >
                <div className="checkout-header">
             
             <div className="row">
                
                 <div className="col-md-3 col-sm-3">
                     <div className="step ">
                         <a href="/CheckoutCart">
                             <div className="number">1</div>
                             <div className="info">
                                 <div className="title">Details du devis</div>
                             </div>
                         </a>
                     </div>
                 </div>
                 
                 <div className="col-md-3 col-sm-3">
                     <div className="step">
                         <a href="/CheckoutInfo">
                             <div className="number">2</div>
                             <div className="info">
                                 <div className="title">Infoamtions client</div>
                             </div>
                         </a>
                     </div>
                 </div>
                
              
                 
                 <div className="col-md-3 col-sm-3">
                     <div className="step active">
                         <a href="/CheckoutComplete">
                             <div className="number">3</div>
                             <div className="info">
                                 <div className="title">Demande envoyée</div>
                             </div>
                         </a>
                     </div>
                 </div>
             </div>
         </div>
                    
                    <div className="checkout-body">
                     
                        <div className="checkout-message">
                            <h1 style={{color:"grey"}}>Merci! <small>Votre demande a été bien envoyée.</small></h1>
                            <div className="table-responsive2">
                                <table className="table table-payment-summary">
                                    <tbody>
                                        <tr>
                                            <td className="field">Date d'envoie</td>
                                            <td className="value">{formattedDate}</td>
                                        </tr>
                                        <tr>
                                            <td className="field">Durée de réception du devis</td>
                                            <td className="value">48 H</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p className="text-muted text-center m-b-0">Si vous avez besoin d'assitance, veuillez appelez notre service commercial au <a href="tel:+21671516321" style={{color:"#777"}}>(+216) 71 516 321</a></p>
                        </div>
                    </div>
                   
                    <div className="checkout-footer text-center">
                        <button onClick={() =>navigate('/Products', { state: { categoryId: null }} )}
                        className="btn btn-white btn-lg p-l-30 p-r-30 m-l-10">Retour vers produits</button>
                    </div>
                </form>
            </div>
         
        </div>
       
    </div>
    );
}
export default CheckoutComplete;